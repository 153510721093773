.building-blocks {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 4px 0px;
  position: relative;
  width: 108px;
}

.building-blocks .container {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-radius: 100px 0px 0px 100px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.building-blocks .state-layer {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  justify-content: center;
  padding: 10px 12px;
  position: relative;
}

.building-blocks .instance-node-2 {
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}

.building-blocks .label-text {
  color: var(--m3-schemes-on-surface);
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.building-blocks .label-text-2 {
  color: var(--m3-schemes-on-secondary-container);
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.building-blocks.hovered {
  flex-direction: column;
}

.building-blocks.pressed {
  flex-direction: column;
}

.building-blocks.focused {
  flex-direction: column;
}

.building-blocks .state-hovered {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  width: 100%;
}

.building-blocks .selected-true {
  background-color: var(--m3-schemes-secondary-container);
}

.building-blocks .state-pressed {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  width: 100%;
}

.building-blocks .state-disabled {
  border-color: #1d1b201f;
}

.building-blocks .label-only {
  flex-direction: column;
}

.building-blocks .state-enabled {
  border-color: var(--m3-schemes-outline);
}

.building-blocks .state-focused {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  width: 100%;
}

.building-blocks .label-icon {
  flex-direction: column;
}

.building-blocks .state-enabled.selected-true {
  flex-direction: column;
}

.building-blocks .container.state-hovered .state-layer {
  width: 100%;
}

.building-blocks .container.state-pressed .state-layer {
  width: 100%;
}

.building-blocks .container.label-only .state-layer {
  width: 100%;
}

.building-blocks .container.state-focused .state-layer {
  width: 100%;
}

.building-blocks .container.label-icon .state-layer {
  width: 100%;
}

.building-blocks .container.selected-true.state-hovered .state-layer {
  background-color: #1d192b14;
}

.building-blocks .container.selected-false.state-hovered .state-layer {
  background-color: #1d1b2014;
}

.building-blocks .container.state-pressed.selected-true .state-layer {
  background-color: #1d192b1f;
}

.building-blocks .container.state-focused.selected-true .state-layer {
  background-color: #1d192b1f;
}

.building-blocks .container.state-focused.selected-false .state-layer {
  background-color: #1d1b201f;
}

.building-blocks .container.state-enabled.selected-true .state-layer {
  width: 100%;
}

.building-blocks .container.state-pressed.selected-false .state-layer {
  background-color: #1d1b201f;
}

.building-blocks .container.state-disabled .label-text {
  opacity: 0.38;
}
