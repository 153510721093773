.create {
  background-color: #0e0f11;
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  width: 100%;
  height: 100vh;
}

.create .overlap-wrapper {
  background-color: #0e0f11;
  height: 1040px;
  /* width: 1512px; */
}

.create .overlap {
  background-image: url(/image-17.png);
  background-position: 50% 50%;
  background-size: cover;
}

.create .frame-2 {
  align-items: flex-start;
  display: flex;
  gap: 24px;
  height: 1040px;
  position: relative;
  width: 100%;
}

.create .design-component-instance-node-2 {
  flex: 0 0 auto !important;
}

.create .side-bar-instance {
  background-color: var(--collection-1-accent-20) !important;
}

.create .side-bar-2 {
  font-family: var(--semibold-16px-font-family) !important;
  font-size: var(--semibold-16px-font-size) !important;
  font-style: var(--semibold-16px-font-style) !important;
  font-weight: var(--semibold-16px-font-weight) !important;
  letter-spacing: var(--semibold-16px-letter-spacing) !important;
  line-height: var(--semibold-16px-line-height) !important;
}

.create .frame-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  padding: 0px 24px;
  position: relative;
}

.create .header {
  align-items: center;
  align-self: stretch;
  background-color: transparent;
  border-radius: var(--shape-corner-small);
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 8px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.create .frame-4 {
  align-items: center;
  /* background-color: var(--collection-1-black); */
  border-radius: var(--shape-corner-small);
  display: inline-flex;
  flex: 0 0 auto;
  gap: var(--size-space-200);
  overflow: hidden;
  padding: 8px;
  position: relative;
}

.create .currency-instance {
  flex: 0 0 auto !important;
  margin-bottom: -1px !important;
  margin-left: -1px !important;
  margin-top: -1px !important;
}

.create .currency-2 {
  background-image: url(/icon-182.svg) !important;
  height: 16px !important;
  width: 16px !important;
}

.create .icon-183-wrapper {
  align-items: center;
  background-color: var(--collection-1-accent-5);
  border-radius: var(--radius-8px);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  overflow: hidden;
  padding: 8px;
  position: relative;
}

.create .icon-instance-node-2 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.create .icons-2 {
  align-items: center;
  border-radius: var(--radius-8px);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  overflow: hidden;
  padding: 8px;
  position: relative;
}

.create .icon-116-wrapper {
  align-items: center;
  background-color: var(--collection-1-accent-20);
  border-radius: var(--shape-corner-extra-large);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  overflow: hidden;
  padding: 8px;
  position: relative;
}

.create .frame-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  overflow-y: scroll;
  padding: 0px 0px 40px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.create .frame-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 100%;
}

.create .transactions-table {
  align-items: flex-start;
  background-color: var(--collection-1-black);
  border: 1px solid;
  border-color: #ffffff33;
  border-radius: var(--shape-corner-large);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--size-space-400);
  overflow: hidden;
  padding: 24px;
  position: relative;
  height: 90px;
  /* margin-left: 9px; */
}

.create .frame-7 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 48px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.create .title {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 4px 8px;
  position: relative;
  width: 100%;
}

.create .frame-8 {
  align-items: center;
  display: flex;
  gap: var(--size-space-400);
  position: relative;
  width: 375px;
}

.create .text-wrapper-5 {
  color: #cac4d0;
  font-family: var(--subheading-font-family);
  font-size: var(--subheading-font-size);
  font-style: var(--subheading-font-style);
  font-weight: var(--subheading-font-weight);
  letter-spacing: var(--subheading-letter-spacing);
  line-height: var(--subheading-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create .frame-9 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: var(--size-space-400);
  justify-content: flex-end;
  position: relative;
}

.create .icon-198-wrapper {
  align-items: center;
  background-color: var(--collection-1-accent-10);
  border-radius: var(--radius-8px);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  overflow: hidden;
  padding: 8px;
  position: relative;
}

.create .frame-10 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--collection-1-BG-2);
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--size-space-400);
  padding: 16px;
  position: relative;
  width: 100%;
}

.create .frame-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--size-space-200);
  position: relative;
  width: 100%;
}

.create .frame-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.create .frame-12 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--shape-corner-small);
  display: flex;
  flex: 0 0 auto;
  gap: var(--size-space-100);
  padding: var(--size-space-400) 0px var(--size-space-400) 0px;
  position: relative;
  width: 100%;
}

.create .participant-wrapper {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: 92px;
}

.create .participant {
  color: #cac4d0;
  font-family: var(--m3-title-medium-font-family);
  font-size: var(--m3-title-medium-font-size);
  font-style: var(--m3-title-medium-font-style);
  font-weight: var(--m3-title-medium-font-weight);
  letter-spacing: var(--m3-title-medium-letter-spacing);
  line-height: var(--m3-title-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create .frame-13 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: var(--size-space-200);
  position: relative;
}

.create .text-field-3 {
  align-items: flex-start;
  border-radius: var(--shape-corner-small) var(--shape-corner-small)
    var(--shape-corner-none) var(--shape-corner-none);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 56px;
  position: relative;
}

.create .text-field-4 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--m3-state-layers-on-secondary-opacity-08);
  border-radius: var(--shape-corner-small) var(--shape-corner-small)
    var(--shape-corner-none) var(--shape-corner-none);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  position: relative;
  width: 100%;
}

.create .state-layer-5 {
  align-items: center;
  align-self: stretch;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  padding: 4px var(--size-space-200) 4px 16px;
  position: relative;
  width: 100%;
}

.create .content-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 48px;
  justify-content: center;
  padding: 4px 0px;
  position: relative;
}

.create .div-wrapper-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.create .label-text-6 {
  color: var(--m3-schemes-outline);
  font-family: var(--m3-body-small-font-family);
  font-size: var(--m3-body-small-font-size);
  font-style: var(--m3-body-small-font-style);
  font-weight: var(--m3-body-small-font-weight);
  letter-spacing: var(--m3-body-small-letter-spacing);
  line-height: var(--m3-body-small-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create .input-text-wrapper {
  align-items: center;
  background: none;
  border: none;
  color: var(--m-3white);
  display: inline-flex;
  flex: 0 0 auto;
  font-family: var(--m3-body-large-font-family);
  font-size: var(--m3-body-large-font-size);
  font-style: var(--m3-body-large-font-style);
  font-weight: var(--m3-body-large-font-weight);
  letter-spacing: var(--m3-body-large-letter-spacing);
  line-height: var(--m3-body-large-line-height);
  margin-top: -1px;
  padding: 0;
  position: relative;
  white-space: nowrap;
}

.create .sort-instance {
  background-image: url(/icon-55.svg) !important;
  height: 16px !important;
  width: 16px !important;
}

.create .active-indicator-2 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.create .swap-2 {
  background-image: url(/icon-56.svg) !important;
}

.create .text-field-5 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--m3-state-layers-on-primary-opacity-08);
  border-radius: var(--shape-corner-small) var(--shape-corner-small) 0px 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.create .state-layer-6 {
  align-items: center;
  align-self: stretch;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  padding: 4px 8px 4px 16px;
  position: relative;
  width: 100%;
}

.create .placeholder-text-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.create .placeholder-text-3 {
  color: var(--m3-schemes-outline-variant);
  font-family: var(--m3-body-large-font-family);
  font-size: var(--m3-body-large-font-size);
  font-style: var(--m3-body-large-font-style);
  font-weight: var(--m3-body-large-font-weight);
  letter-spacing: var(--m3-body-large-letter-spacing);
  line-height: var(--m3-body-large-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create .sort-2-instance {
  background-image: url(/icon-57.svg) !important;
  height: 16px !important;
  width: 16px !important;
}

.create .line {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.create .frame-14 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.create .frame-15 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.create .text-wrapper-6 {
  color: #cac4d0;
  font-family: "Roboto", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create .frame-16 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: var(--size-space-200);
  position: relative;
  width: 100%;
}

.create .frame-17 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.create .input-text-2 {
  color: var(--m-3white);
  font-family: var(--m3-body-large-font-family);
  font-size: var(--m3-body-large-font-size);
  font-style: var(--m3-body-large-font-style);
  font-weight: var(--m3-body-large-font-weight);
  letter-spacing: var(--m3-body-large-letter-spacing);
  line-height: var(--m3-body-large-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create .mini-potfolio-card {
  align-items: flex-start;
  background-color: var(--collection-1-black);
  border: 1px solid;
  border-color: #ffffff33;
  border-radius: var(--shape-corner-small);
  display: flex;
  flex-direction: column;
  gap: var(--size-space-400);
  height: 554px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 16px;
  position: relative;
  width: 404px;
}

.create .frame-18 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 55px;
}

.create .text-wrapper-7 {
  color: #cac4d0;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.15px;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create .icons-wrapper {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
}

.create .frame-19 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.create .frame-20 {
  align-items: center;
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  justify-content: flex-end;
  padding: var(--size-space-300) var(--size-space-0) 12px var(--size-space-0);
  position: relative;
}

.create .frame-1000008301 {
  color: var(--m3-schemes-outline-variant) !important;
}

.create .frame-21 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: var(--size-space-200);
  position: relative;
  width: 100%;
}

.create .button-instance {
  background-color: var(--collection-1-accent) !important;
  display: flex !important;
  height: 48px !important;
  width: 236px !important;
  color: white;
  margin-right: 10px;
}

.create .button-2 {
  margin-top: unset !important;
}

.create .button-3 {
  border: 1px solid var(--collection-1-accent) !important;
  color: var(--collection-1-accent) !important;
  background-color: black;
  height: 48px !important;
}

.create .button-4 {
  color: var(--collection-1-accent) !important;
  margin-top: unset !important;
}

.create .mini-potfolio-card-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #181818;
  border-radius: var(--shape-corner-small);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--tokens-spacing-spacing-md);
  margin-left: -1px;
  margin-right: -1px;
  overflow-y: scroll;
  padding: 16px;
  position: relative;
  margin-top: 20px;
  /* width: 100%; */
  /* margin-left: 9px; */
}

.create .frame-22 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
  width: 300.5px;
}

.create .frame-23 {
  align-items: center;
  align-self: stretch;
  border-radius: var(--tokens-radius-radius-rounded);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--tokens-spacing-spacing-xs);
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;
}

.create .table-heading {
  align-items: center;
  align-self: stretch;
  background-color: #000000;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #f8f8f833;
  display: flex;
  height: 62px;
  justify-content: space-between;
  margin-left: -1px;
  margin-right: -1px;
  padding: 8px;
  position: relative;
  width: 100%;
  z-index: 6;
}

.create .row-texts {
  gap: var(--size-space-200);
  height: 26px;
  position: relative;
  width: 26px;
}

.create .frame-wrapper-2 {
  align-items: center;
  display: flex;
  gap: var(--size-space-200);
  position: relative;
  width: 80px;
}

.create .div-7 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: var(--size-space-100);
  position: relative;
}

.create .cost-efficiency {
  color: var(--collection-1-white);
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create .frame-wrapper-3 {
  align-items: center;
  display: flex;
  gap: var(--size-space-200);
  position: relative;
  width: 92px;
}

.create .table-column-title {
  align-items: center;
  display: flex;
  gap: var(--size-space-200);
  position: relative;
  width: 120px;
}

.create .cost-efficiency-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: var(--size-space-100);
  justify-content: center;
  position: relative;
}

.create .home {
  background-image: url(/icon-200.svg) !important;
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.create .table-column-title-2 {
  align-items: center;
  display: flex;
  gap: var(--size-space-200);
  position: relative;
  width: 124px;
}

.create .row-texts-2 {
  gap: var(--size-space-200);
  height: 16px;
  position: relative;
  width: 16px;
}

.create .balance-sheet {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: var(--size-space-200) 0px var(--size-space-200) 0px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.create .tr {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: var(--tokens-padding-padding-basic);
  position: relative;
  width: 100%;
}

.create .row-texts-3 {
  align-items: center;
  display: flex;
  gap: var(--size-space-200);
  padding: 0px 12px 0px 0px;
  position: relative;
  width: 80px;
}

.create .cost-efficiency-2 {
  color: var(--collection-1-white);
  font-family: var(--m3-body-medium-font-family);
  font-size: var(--m3-body-medium-font-size);
  font-style: var(--m3-body-medium-font-style);
  font-weight: var(--m3-body-medium-font-weight);
  letter-spacing: var(--m3-body-medium-letter-spacing);
  line-height: var(--m3-body-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create .cost-efficiency-3 {
  color: var(--collection-1-white);
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create .menu-list-item {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 120px;
}

.create .content-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  padding: 0px 12px;
  position: relative;
  width: 100%;
}

.create .content-4 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.create .label-text-7 {
  align-self: stretch;
  color: var(--collection-1-white);
  font-family: var(--m3-body-medium-font-family);
  font-size: var(--m3-body-medium-font-size);
  font-style: var(--m3-body-medium-font-style);
  font-weight: var(--m3-body-medium-font-weight);
  letter-spacing: var(--m3-body-medium-letter-spacing);
  line-height: var(--m3-body-medium-line-height);
  margin-top: -1px;
  position: relative;
}

.create .supporting-text-2 {
  color: var(--m3-schemes-outline-variant);
  font-family: var(--m3-body-small-font-family);
  font-size: var(--m3-body-small-font-size);
  font-style: var(--m3-body-small-font-style);
  font-weight: var(--m3-body-small-font-weight);
  letter-spacing: var(--m3-body-small-letter-spacing);
  line-height: var(--m3-body-small-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create .finalised-instance {
  display: flex !important;
  height: unset !important;
  width: 60px !important;
}

.create .finalised-2 {
  height: unset !important;
  padding: 4px 8px !important;
}

.create .finalised-3 {
  font-family: var(--m3-label-medium-font-family) !important;
  font-size: var(--m3-label-medium-font-size) !important;
  font-style: var(--m3-label-medium-font-style) !important;
  font-weight: var(--m3-label-medium-font-weight) !important;
  letter-spacing: var(--m3-label-medium-letter-spacing) !important;
  line-height: var(--m3-label-medium-line-height) !important;
}

.create .options-instance {
  background-image: url(/system-uicons-menu-vertical-11.svg) !important;
  position: relative !important;
}

.create .tr-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: #f8f8f80d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: var(--size-space-200) 0px var(--size-space-200) 0px;
  position: relative;
  width: 100%;
  z-index: -1;
}

.create .balance-sheet-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: var(--size-space-200) 0px var(--size-space-200) 0px;
  position: relative;
  width: 100%;
  z-index: -2;
}

.create .supporting-text-3 {
  color: var(--m3-schemes-outline-variant);
  font-family: var(--m3-body-small-font-family);
  font-size: var(--m3-body-small-font-size);
  font-style: var(--m3-body-small-font-style);
  font-weight: var(--m3-body-small-font-weight);
  letter-spacing: var(--m3-body-small-letter-spacing);
  line-height: var(--m3-body-small-line-height);
  margin-right: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create .balance-sheet-3 {
  align-items: center;
  align-self: stretch;
  background-color: #f8f8f80d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: var(--size-space-200) 0px var(--size-space-200) 0px;
  position: relative;
  width: 100%;
  z-index: -3;
}

.create .options-2 {
  background-image: url(/system-uicons-menu-vertical-14.png) !important;
  position: relative !important;
}

.create .balance-sheet-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: var(--size-space-200) 0px var(--size-space-200) 0px;
  position: relative;
  width: 100%;
  z-index: -4;
}

.create .balance-sheet-5 {
  align-items: center;
  align-self: stretch;
  background-color: #f8f8f80d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: var(--size-space-200) 0px var(--size-space-200) 0px;
  position: relative;
  width: 100%;
  z-index: -5;
}

.create .createGrid {
  width: 100%;
}

.create .createGrid .rs-input {
  background-color: #181818;
  border: 0px;
  height: 50px;
  color: white;
}

.create .createGrid .rs-row {
  height: 60px;
}

.create .createGrid .labels {
  color: white;
  font-size: 18px;
  padding-top: 12px;
}

.create .createGrid .central {
  text-align: center;
}

.create .createGrid .autoMargins {
  margin: auto;
  margin-top: 10px;
}

.create .createGrid .hr {
  border-color: grey;
}

.create .createGrid .buttons {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.create .code-button {
  background-color: #3b2219;
}

.create .code-button-top {
  background-color: #3b2219;
  position: absolute;
  right: 20px;
}

.create logoLarge {
  height: 60px;
  object-fit: cover;
  position: relative;
  width: 178.26px;
  /* padding: 20px; */
  margin-top: 5px;
}

.submitButton {
  background-color: var(--collection-1-accent) !important;
  color: white !important;
  margin-top: 15px;
  position: absolute;
  top: 11px;
  right: 25px;
}

.logoutButton {
  align-items: center;
  align-self: stretch;
  background-color: rgb(40, 40, 40) !important;
  border-radius: 8px;
  color: var(--collection-1-white);
  display: flex;
  font-family: "Roboto-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 24px;
  padding: 10px 40px;
  position: relative;
  width: 255px;
  white-space: nowrap;
}

.logoutButton:hover {
  align-items: center;
  align-self: stretch;
  background-color: rgb(40, 40, 40) !important;
  border-radius: 8px;
  color: var(--collection-1-white);
  display: flex;
  font-family: "Roboto-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 24px;
  padding: 10px 40px;
  position: relative;
  width: 255px;
  white-space: nowrap;
}

.submitGrid {
  width: 100%;
}

.rs-table-row-header {
  border-radius: 5px 5px 0px 0px;
}

.rs-table-row-header .rs-table-cell {
  background-color: #000000 !important; /* Change header background color */
  color: #eeeeee; /* Change header text color */
  font-size: 14px;
}

.rs-table-body-row .rs-table-cell {
  background-color: #fff; /* Change row background color */
  color: #ffffff; /* Change row text color */
  border-bottom: 1px solid #ddd; /* Add border to rows */
}

.rs-table-row:nth-child(odd) .rs-table-cell {
  background-color: #1c1c1c;
}

.rs-table-row:nth-child(even) .rs-table-cell {
  background-color: #181818;
}

.rs-table-cell {
  color: #fff;
  border: 0px;
}

.rs-table-row:not(.rs-table-row-rowspan) {
  border: 0px;
}

.deleteButton {
  border: 1px solid red !important;
  color: red !important;
  background-color: #ffb2b2 !important;
}

.whiteText {
  color: white;
}

.file-input {
  display: none;
}

.file-label {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  line-height: 20px;
  background-color: #3c3f43;
  border: none;
  display: inline-block;
  font-weight: 400;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.file-name {
  margin-left: 10px;
  font-style: italic;
  position: relative;
  left: 205px;
  top: -35px;
}