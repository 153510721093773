.button {
  align-items: center;
  border-radius: 100px;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.button .state-layer {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  position: relative;
  width: 100%;
}

.button .icon-2 {
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}

.button .label-text {
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.button .label-text-2 {
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.button.outlined {
  border: 1px solid;
}

.button.tonal.hovered {
  background-color: var(--m3-schemes-secondary-container);
  box-shadow: var(--m3-elevation-light-1);
}

.button.hovered.elevated {
  background-color: var(--m3-schemes-surface-container-low);
  box-shadow: var(--m3-elevation-light-2);
}

.button.focused.filled {
  background-color: var(--m3-schemes-primary);
}

.button.enabled.tonal {
  background-color: var(--m3-schemes-secondary-container);
}

.button.enabled.filled {
  background-color: var(--m3-schemes-primary);
}

.button.pressed.elevated {
  background-color: var(--m3-schemes-surface-container-low);
  box-shadow: var(--m3-elevation-light-1);
}

.button.pressed.tonal {
  background-color: var(--m3-schemes-secondary-container);
}

.button.outlined.pressed {
  border-color: var(--m3-schemes-outline);
}

.button.hovered.filled {
  background-color: var(--m3-schemes-primary);
  box-shadow: var(--m3-elevation-light-1);
}

.button.outlined.focused {
  border-color: var(--m3-schemes-primary);
}

.button.outlined.enabled {
  border-color: var(--m3-schemes-outline);
}

.button.enabled.elevated {
  background-color: var(--m3-schemes-surface-container-low);
  box-shadow: var(--m3-elevation-light-1);
}

.button.outlined.disabled {
  border-color: #1d1b201f;
}

.button.pressed.filled {
  background-color: var(--m3-schemes-primary);
}

.button.outlined.hovered {
  border-color: var(--m3-schemes-outline);
}

.button.focused.tonal {
  background-color: var(--m3-schemes-secondary-container);
}

.button.focused.elevated {
  background-color: var(--m3-schemes-surface-container-low);
  box-shadow: var(--m3-elevation-light-1);
}

.button .state-hovered {
  justify-content: center;
}

.button .style-text {
  justify-content: center;
}

.button .state-pressed {
  justify-content: center;
}

.button .style-filled {
  justify-content: center;
}

.button .show-icon-false {
  justify-content: center;
}

.button .style-tonal {
  justify-content: center;
}

.button .state-enabled {
  justify-content: center;
}

.button .state-focused {
  justify-content: center;
}

.button .style-tonal.state-hovered {
  background-color: #1d192b14;
}

.button .state-hovered.style-elevated {
  background-color: #65558f14;
}

.button .show-icon-true.style-filled {
  padding: 10px 24px 10px 16px;
}

.button .state-focused.style-filled {
  background-color: #ffffff1f;
}

.button .show-icon-false.style-filled {
  padding: 10px 24px;
}

.button .state-pressed.style-elevated {
  background-color: #65558f1f;
}

.button .state-pressed.style-tonal {
  background-color: #1d192b1f;
}

.button .style-outlined.state-pressed {
  background-color: #65558f1f;
}

.button .state-hovered.style-filled {
  background-color: #ffffff14;
}

.button .style-outlined.state-focused {
  background-color: #65558f1f;
}

.button .state-disabled.style-elevated {
  background-color: #1d1b201f;
}

.button .style-tonal.state-disabled {
  background-color: #1d1b201f;
}

.button .show-icon-false.style-outlined {
  padding: 10px 24px;
}

.button .style-outlined.show-icon-true {
  padding: 10px 24px 10px 16px;
}

.button .show-icon-false.style-tonal {
  padding: 10px 24px;
}

.button .show-icon-false.style-elevated {
  padding: 10px 24px;
}

.button .state-focused.style-text {
  background-color: #65558f1f;
}

.button .state-pressed.style-filled {
  background-color: #ffffff1f;
}

.button .style-outlined.state-hovered {
  background-color: #65558f14;
}

.button .show-icon-true.style-elevated {
  padding: 10px 24px 10px 16px;
}

.button .show-icon-true.style-text {
  padding: 10px 16px 10px 12px;
}

.button .state-focused.style-tonal {
  background-color: #1d192b1f;
}

.button .state-focused.style-elevated {
  background-color: #65558f1f;
}

.button .style-text.state-hovered {
  background-color: #65558f14;
}

.button .show-icon-true.style-tonal {
  padding: 10px 24px 10px 16px;
}

.button .state-disabled.style-filled {
  background-color: #1d1b201f;
}

.button .state-pressed.style-text {
  background-color: #65558f1f;
}

.button .show-icon-false.style-text {
  padding: 10px 12px;
}

.button .state-focused.style-filled.show-icon-true {
  border-radius: 4px;
  overflow: hidden;
}

.button .show-icon-true.state-hovered.style-filled {
  border-radius: 4px;
  overflow: hidden;
}

.button .state-layer.state-disabled .label-text {
  color: var(--m3-schemes-on-surface);
  opacity: 0.38;
}

.button .state-layer.style-tonal.state-hovered .label-text {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.state-hovered.style-elevated .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-enabled.style-text .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-focused.style-filled .label-text {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.state-enabled.style-tonal .label-text {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.state-enabled.style-filled .label-text {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.state-pressed.style-elevated .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-pressed.style-tonal .label-text {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.style-outlined.state-pressed .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-hovered.style-filled .label-text {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.style-outlined.state-focused .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.style-outlined.state-enabled .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-enabled.style-elevated .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-focused.style-text .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-pressed.style-filled .label-text {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.style-outlined.state-hovered .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-focused.style-tonal .label-text {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.state-focused.style-elevated .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.style-text.state-hovered .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-pressed.style-text .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-disabled .label-text-2 {
  color: var(--m3-schemes-on-surface);
  opacity: 0.38;
}

.button .state-layer.style-tonal.state-hovered .label-text-2 {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.state-hovered.style-elevated .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-enabled.style-text .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-focused.style-filled .label-text-2 {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.state-enabled.style-tonal .label-text-2 {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.state-enabled.style-filled .label-text-2 {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.state-pressed.style-elevated .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-pressed.style-tonal .label-text-2 {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.style-outlined.state-pressed .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-hovered.style-filled .label-text-2 {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.style-outlined.state-focused .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.style-outlined.state-enabled .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-enabled.style-elevated .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-focused.style-text .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-pressed.style-filled .label-text-2 {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.style-outlined.state-hovered .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-focused.style-tonal .label-text-2 {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.state-focused.style-elevated .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.style-text.state-hovered .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-pressed.style-text .label-text-2 {
  color: var(--m3-schemes-primary);
}
