/* nav bar */

.sideBar .logoLarge {
  height: 100px;
  object-fit: cover;
  padding: 20px;
  margin-top: 5px;
  /* position: relative; */
  /* width: 178.26px; */
}

.sideBar .logoSmall {
  height: 70px;
  margin-bottom: 30px;
  object-fit: cover;
  position: relative;
  width: 60px;
  padding: 10px 0px 10px 14px
}
  
.sideBar {
  -webkit-backdrop-filter: blur(24px) brightness(100%);
  align-items: flex-start;
  backdrop-filter: blur(24px) brightness(100%);
  background-color: var(--collection-1-BG-2);
  border-color: var(--token-design-1-border-color-white-border);
  border-right-style: solid;
  border-right-width: 1.5px;
  box-shadow: 4px 0px 16px #0000000a;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  height: 100vh;
  margin-bottom: -0.75px;
  margin-left: -0.75px;
  margin-right: -0.75px;
  margin-top: -0.75px;
  position: relative;
}

.sideBar .rs-sidenav-nav {
  margin-left: 7px;
}


.sideBar .rs-sidenav-item a {
  text-align: center;
}

.sideBar .rs-icon {
  top: 12px !important;
  left: 16px !important;
  margin-right: 10px !important;
  height: 24px !important;
  width: 24px !important;
  color: white;
}

.sideBar .btn-navbar-small {
  position: absolute;
  top: 85px;
  right: -10px;
  background-color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.sideBar .btn-navbar-large {
  position: absolute;
  top: 85px;
  right: -10px;
  background-color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.sideBar .rs-sidenav-subtle .rs-sidenav-item:hover {
  background-color: rgb(40, 40, 40) !important;
  border-radius: 5px;
}
.sideBar .rs-sidenav-item-focus {
  outline: 0px !important;
}

.sideBar .logoutButtonSmall {
  align-items: center;
  align-self: stretch;
  background-color: rgb(40, 40, 40) !important;
  border-radius: 5px;
  color: var(--collection-1-white);
  display: flex;
  /* font-family: "Roboto-Medium", Helvetica; */
  /* font-size: 16px; */
  /* font-weight: 500; */
  height: 48px;
  justify-content: center;
  letter-spacing: 0;
  /* line-height: 24px; */
  /* padding: 10px 40px; */
  position: relative;
  width: 36px;
  white-space: nowrap;
}

.sideBar .logoutButtonLarge {
  align-items: center;
  align-self: stretch;
  background-color: rgb(40, 40, 40) !important;
  border-radius: 8px;
  color: var(--collection-1-white);
  display: flex;
  font-family: "Roboto-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 24px;
  padding: 10px 40px;
  position: relative;
  width: 255px;
  white-space: nowrap;
}

.sideBar .bottom-content {
  /* padding: 20px; */
  position: absolute;
  bottom: 15px;
}

.sideBar .category-exit-1 {
  margin-right: 10px;
}

.rs-sidenav-subtle .rs-sidenav-item.rs-sidenav-item-active {
  color: white !important;
  background-color: #3b2219;
}

.sideBar .rs-sidenav-subtle .rs-sidenav-item:hover {
  background-color: #3b2219 !important;
}

.sideBar {
  color: grey;
}