body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



:root {
  --collection-1-accent: rgba(243, 118, 74, 1);
  --collection-1-accent-10: rgba(243, 118, 74, 0.1);
  --collection-1-accent-20: rgba(243, 118, 74, 0.2);
  --collection-1-accent-5: rgba(243, 118, 74, 0.05);
  --collection-1-accent-50: rgba(243, 118, 74, 0.5);
  --collection-1-BG: rgba(17, 17, 17, 1);
  --collection-1-BG-2: rgba(14, 14, 14, 1);
  --collection-1-black: rgba(12, 12, 12, 1);
  --collection-1-white: rgba(248, 248, 248, 1);
  --collection-1-yellow: rgba(254, 192, 32, 1);
  --m3-body-large-font-family: "Roboto", Helvetica;
  --m3-body-large-font-size: 16px;
  --m3-body-large-font-style: normal;
  --m3-body-large-font-weight: 400;
  --m3-body-large-letter-spacing: 0.5px;
  --m3-body-large-line-height: 24px;
  --m3-schemes-error: rgba(179, 38, 30, 1);
  --m3-schemes-on-surface: rgba(29, 27, 32, 1);
  --m3-schemes-on-surface-variant: rgba(73, 69, 79, 1);
  --m3-schemes-primary: rgba(101, 85, 143, 1);
  --m3-state-layers-error-opacity-08: rgba(179, 38, 30, 0.08);
  --m3-state-layers-error-opacity-12: rgba(179, 38, 30, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(29, 27, 32, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(29, 27, 32, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(101, 85, 143, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(101, 85, 143, 0.12);
  --neutralwhite-100: rgba(255, 255, 255, 1);
  --shape-corner-small: 5px;
  --shape-corner-large: 10px;
}

/*

To enable a theme in your HTML, simply add one of the following data attributes to an HTML element, like so:

<body data-m3-mode="light">
    <!-- the rest of your content -->
</body>

You can apply the theme on any DOM node, not just the `body`

*/

[data-m3-mode="light"] {
  --m3-schemes-error: rgba(179, 38, 30, 1);
  --m3-schemes-on-surface: rgba(29, 27, 32, 1);
  --m3-schemes-on-surface-variant: rgba(73, 69, 79, 1);
  --m3-schemes-primary: rgba(101, 85, 143, 1);
  --m3-state-layers-error-opacity-08: rgba(179, 38, 30, 0.08);
  --m3-state-layers-error-opacity-12: rgba(179, 38, 30, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(29, 27, 32, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(29, 27, 32, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(101, 85, 143, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(101, 85, 143, 0.12);
}

[data-m3-mode="light-high-contrast"] {
  --m3-schemes-error: rgba(68, 15, 14, 1);
  --m3-schemes-on-surface: rgba(0, 0, 0, 1);
  --m3-schemes-on-surface-variant: rgba(37, 35, 43, 1);
  --m3-schemes-primary: rgba(39, 23, 78, 1);
  --m3-state-layers-error-opacity-08: rgba(68, 15, 14, 0.08);
  --m3-state-layers-error-opacity-12: rgba(68, 15, 14, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(0, 0, 0, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(0, 0, 0, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(39, 23, 78, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(39, 23, 78, 0.12);
}

[data-m3-mode="light-medium-contrast"] {
  --m3-schemes-error: rgba(110, 47, 44, 1);
  --m3-schemes-on-surface: rgba(29, 27, 32, 1);
  --m3-schemes-on-surface-variant: rgba(69, 65, 74, 1);
  --m3-schemes-primary: rgba(73, 57, 113, 1);
  --m3-state-layers-error-opacity-08: rgba(110, 47, 44, 0.08);
  --m3-state-layers-error-opacity-12: rgba(110, 47, 44, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(29, 27, 32, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(29, 27, 32, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(73, 57, 113, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(73, 57, 113, 0.12);
}

[data-m3-mode="dark"] {
  --m3-schemes-error: rgba(242, 184, 181, 1);
  --m3-schemes-on-surface: rgba(230, 224, 233, 1);
  --m3-schemes-on-surface-variant: rgba(202, 196, 208, 1);
  --m3-schemes-primary: rgba(208, 188, 254, 1);
  --m3-state-layers-error-opacity-08: rgba(242, 184, 181, 0.08);
  --m3-state-layers-error-opacity-12: rgba(242, 184, 181, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(230, 224, 233, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(230, 224, 233, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(208, 188, 254, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(208, 188, 254, 0.12);
  --color-background-positive-secondary: rgba(153, 255, 130, 0.349);
}

[data-m3-mode="dark-high-contrast"] {
  --m3-schemes-error: rgba(255, 249, 249, 1);
  --m3-schemes-on-surface: rgba(255, 255, 255, 1);
  --m3-schemes-on-surface-variant: rgba(255, 249, 255, 1);
  --m3-schemes-primary: rgba(255, 249, 255, 1);
  --m3-state-layers-error-opacity-08: rgba(255, 249, 249, 0.08);
  --m3-state-layers-error-opacity-12: rgba(255, 249, 249, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(255, 255, 255, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(255, 255, 255, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(255, 249, 255, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(255, 249, 255, 0.12);
}

[data-m3-mode="dark-medium-contrast"] {
  --m3-schemes-error: rgba(255, 185, 179, 1);
  --m3-schemes-on-surface: rgba(255, 249, 255, 1);
  --m3-schemes-on-surface-variant: rgba(206, 200, 212, 1);
  --m3-schemes-primary: rgba(211, 193, 255, 1);
  --m3-state-layers-error-opacity-08: rgba(255, 185, 179, 0.08);
  --m3-state-layers-error-opacity-12: rgba(255, 185, 179, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(255, 249, 255, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(255, 249, 255, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(211, 193, 255, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(211, 193, 255, 0.12);
}

[data-m3-mode="monochrome-light"] {
  --m3-schemes-error: rgba(179, 38, 30, 1);
  --m3-schemes-on-surface: rgba(27, 27, 27, 1);
  --m3-schemes-on-surface-variant: rgba(71, 71, 71, 1);
  --m3-schemes-primary: rgba(0, 0, 0, 1);
  --m3-state-layers-error-opacity-08: rgba(179, 38, 30, 0.08);
  --m3-state-layers-error-opacity-12: rgba(179, 38, 30, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(27, 27, 27, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(27, 27, 27, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(0, 0, 0, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(0, 0, 0, 0.12);
}

[data-m3-mode="monochrome-dark"] {
  --m3-schemes-error: rgba(242, 184, 181, 1);
  --m3-schemes-on-surface: rgba(226, 226, 226, 1);
  --m3-schemes-on-surface-variant: rgba(198, 198, 198, 1);
  --m3-schemes-primary: rgba(255, 255, 255, 1);
  --m3-state-layers-error-opacity-08: rgba(242, 184, 181, 0.08);
  --m3-state-layers-error-opacity-12: rgba(242, 184, 181, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(226, 226, 226, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(226, 226, 226, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(255, 255, 255, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(255, 255, 255, 0.12);
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.fm-preloader-view,
.fm-overlay-preloader {
  display: none !important;
}

html, body {
  height: 100%;
  margin: 0;
 }

table {
  width: 80%;
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding: 5px;
  text-align: left;
}

.wrapper {
  padding: 20px;
}

.login {
    background-color: #0e0f11;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }
  
  .login .overlap-wrapper {
    background-color: #0e0f11;
    height: 1040px;
    overflow: hidden;
    width: 1512px;
  }
  
  .login .overlap {
    height: 1172px;
    left: -189px;
    position: relative;
    top: -132px;
    width: 1890px;
  }
  
  .login .BG {
    height: 1157px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 1890px;
  }
  
  .login .overlap-group {
    height: 1157px;
    position: relative;
  }
  
  .login .pattern {
    height: 1019px;
    left: 0;
    position: absolute;
    top: 45px;
    width: 1890px;
  }
  
  .login .div {
    height: 1019px;
    left: 179px;
    position: relative;
    width: 1522px;
  }
  
  .login .pattern-2 {
    height: 1019px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1510px;
  }
  
  .login .line {
    height: 948px;
    left: 760px;
    object-fit: cover;
    position: absolute;
    top: 72px;
    width: 1px;
  }
  
  .login .overlap-2 {
    height: 921px;
    left: 838px;
    position: absolute;
    top: 72px;
    width: 673px;
  }
  
  .login .img {
    height: 921px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 455px;
  }
  
  .login .line-2 {
    height: 327px;
    left: 172px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 501px;
  }
  
  .login .line-3 {
    height: 541px;
    left: 84px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 552px;
  }
  
  .login .overlap-group-2 {
    height: 861px;
    left: 10px;
    position: absolute;
    top: 72px;
    width: 671px;
  }
  
  .login .line-4 {
    height: 861px;
    left: 216px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 455px;
  }
  
  .login .line-5 {
    height: 317px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 490px;
  }
  
  .login .line-6 {
    height: 540px;
    left: 6px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 552px;
  }
  
  .login .pattern-3 {
    height: 489px;
    left: 10px;
    position: absolute;
    top: 72px;
    width: 1512px;
  }
  
  .login .rectangle {
    background: linear-gradient(180deg, rgba(14, 15, 17, 0) 0%, rgb(14, 15, 17) 35.86%);
    height: 1157px;
    left: 45px;
    position: absolute;
    top: 0;
    width: 1800px;
  }
  
  .login .gradient {
    background-color: #f3764ab8;
    border-radius: 500px/100px;
    filter: blur(600px);
    height: 200px;
    left: 445px;
    position: absolute;
    top: 0;
    width: 1000px;
  }
  
  .login .content {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
    left: 640px;
    position: absolute;
    top: 354px;
  }
  
  .login .frame {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
    position: relative;
    /* width: 478px; */
  }
  
  .login .title {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    grid-gap: 24px;
    gap: 24px;
    position: relative;
  }
  
  .login .text-wrapper {
    color: var(--collection-1-white);
    font-family: "Roboto-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .login .title-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    grid-gap: 24px;
    gap: 24px;
    position: relative;
    width: 100%;
  }
  
  .login .white-logo {
    height: 60.26px;
    object-fit: cover;
    position: relative;
    width: 64px;
  }
  
  .login .p {
    color: var(--collection-1-white);
    font-family: "Roboto-Regular", Helvetica;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .login .content-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
    position: relative;
  }
  
  .login .form {
    align-items: flex-start;
    background-color: #ffffff29;
    border: 1px solid;
    border-color: #ffffff0f;
    border-radius: 16px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px;
    overflow: hidden;
    padding: 48px 48px 40px;
    position: relative;
    width: 584px;
  }
  
  .login .dot {
    height: 200px;
    left: 0;
    position: absolute;
    top: 196px;
    width: 584px;
  }
  
  .login .frame-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
    position: relative;
    width: 100%;
  }
  
  .login .text-field {
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    flex-direction: column;
    height: 56px;
    position: relative;
    width: 100%;
  }
  
  .login .state-layer-wrapper {
    align-items: flex-start;
    align-self: stretch;
    background-color: #e6e0e914;
    border: 1px solid;
    border-color: #ffffff1f;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    flex-grow: 1;
    grid-gap: 10px;
    gap: 10px;
    position: relative;
    width: 100%;
  }
  
  .login .state-layer-2 {
    align-items: center;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    flex: 1 1;
    flex-grow: 1;
    padding: 4px 0px;
    position: relative;
    width: 100%;
  }
  
  .login .frame-3 {
    align-items: center;
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    height: 48px;
    justify-content: center;
    position: relative;
    width: 48px;
  }
  
  .login .icon-instance-node {
    height: 20px !important;
    position: relative !important;
    width: 20px !important;
  }
  
  .login .label-text-wrapper {
    align-items: flex-start;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    flex-grow: 1;
    height: 48px;
    justify-content: center;
    padding: 4px 0px;
    position: relative;
  }
  
  .login .label-text {
    align-items: center;
    background: none;
    border: 0;
    color: var(--collection-1-white);
    display: inline-flex;
    flex: 0 0 auto;
    font-family: var(--m3-body-large-font-family);
    font-size: var(--m3-body-large-font-size);
    font-style: var(--m3-body-large-font-style);
    font-weight: var(--m3-body-large-font-weight);
    height: 23px;
    letter-spacing: var(--m3-body-large-letter-spacing);
    line-height: var(--m3-body-large-line-height);
    margin-top: -1px;
    padding: 0;
    position: relative;
    white-space: nowrap;
    width: 385px;
  }
  
  .login .active-indicator {
    align-self: stretch;
    height: 1px;
    object-fit: cover;
    position: relative;
    width: 100%;
  }
  
  .login .frame-4 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    position: relative;
    width: 100%;
  }
  
  .login .frame-5 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    grid-gap: 8px;
    gap: 8px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }
  
  .login .text-wrapper-2 {
    color: var(--collection-1-accent);
    font-family: "Roboto-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .login .input {
    align-self: stretch;
    background: none;
    border: 0;
    color: var(--collection-1-white);
    font-family: var(--m3-body-large-font-family);
    font-size: var(--m3-body-large-font-size);
    font-style: var(--m3-body-large-font-style);
    font-weight: var(--m3-body-large-font-weight);
    height: 23px;
    letter-spacing: var(--m3-body-large-letter-spacing);
    line-height: var(--m3-body-large-line-height);
    margin-top: -1px;
    padding: 0;
    position: relative;
    white-space: nowrap;
    width: 100%;
  }
  
  .login .frame-6 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .login .checkboxes-instance {
    flex: 0 0 auto !important;
  }
  
  .login .design-component-instance-node {
    background-color: var(--collection-1-accent) !important;
  }
  
  .login .text-wrapper-3 {
    color: var(--neutralwhite-100);
    font-family: "Roboto-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
    margin-left: -8px;
    position: relative;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .loginButton {
    align-items: center;
    align-self: stretch;
    background-color: var(--collection-1-accent) !important;
    border-radius: 8px;
    color: var(--collection-1-black);
    display: flex;
    font-family: "Roboto-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    height: 48px;
    justify-content: center;
    letter-spacing: 0;
    line-height: 24px;
    padding: 10px 40px;
    position: relative;
    white-space: nowrap;
    width: 300px;
    /* width: fit-content; */
  }
  
  .login .foot {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 584px;
  }
  
  .login .frame-7 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
    position: relative;
  }
  
  .login .ellipse {
    background-color: #454a53;
    border-radius: 1.5px;
    height: 3px;
    position: relative;
    width: 3px;
  }
  
  .login .frame-8 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    grid-gap: 8px;
    gap: 8px;
    position: relative;
  }
  
  .login .bee {
    height: 123px;
    left: 883px;
    object-fit: cover;
    position: absolute;
    top: 188px;
    width: 123px;
  }

  .inputGroup {
    align-self: stretch;
    border: 1px solid !important;
    border-color: #ffffff1f;
    display: flex;
    flex: 1 1;
    flex-grow: 1;
    position: relative;
    width: 100%;
  }
  
  .inputGroup .inputField {
    background-color: #e6e0e914 !important;
    height: 64px;
    color: white;
  }

  .cursorPointer {
    cursor: pointer !important;
  }

  .loginButton {
    align-items: center;
    align-self: stretch;
    background-color: var(--collection-1-accent);
    border-radius: 8px;
    color: var(--collection-1-black);
    display: flex;
    font-family: "Roboto-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    height: 48px;
    justify-content: center;
    letter-spacing: 0;
    line-height: 24px;
    padding: 10px 40px;
    position: relative;
    width: 100%;
    white-space: nowrap;
  }
  
/* nav bar */

.sideBar .logoLarge {
  height: 100px;
  object-fit: cover;
  padding: 20px;
  margin-top: 5px;
  /* position: relative; */
  /* width: 178.26px; */
}

.sideBar .logoSmall {
  height: 70px;
  margin-bottom: 30px;
  object-fit: cover;
  position: relative;
  width: 60px;
  padding: 10px 0px 10px 14px
}
  
.sideBar {
  -webkit-backdrop-filter: blur(24px) brightness(100%);
  align-items: flex-start;
  backdrop-filter: blur(24px) brightness(100%);
  background-color: var(--collection-1-BG-2);
  border-color: var(--token-design-1-border-color-white-border);
  border-right-style: solid;
  border-right-width: 1.5px;
  box-shadow: 4px 0px 16px #0000000a;
  display: inline-flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
  height: 100vh;
  margin-bottom: -0.75px;
  margin-left: -0.75px;
  margin-right: -0.75px;
  margin-top: -0.75px;
  position: relative;
}

.sideBar .rs-sidenav-nav {
  margin-left: 7px;
}


.sideBar .rs-sidenav-item a {
  text-align: center;
}

.sideBar .rs-icon {
  top: 12px !important;
  left: 16px !important;
  margin-right: 10px !important;
  height: 24px !important;
  width: 24px !important;
  color: white;
}

.sideBar .btn-navbar-small {
  position: absolute;
  top: 85px;
  right: -10px;
  background-color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.sideBar .btn-navbar-large {
  position: absolute;
  top: 85px;
  right: -10px;
  background-color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.sideBar .rs-sidenav-subtle .rs-sidenav-item:hover {
  background-color: rgb(40, 40, 40) !important;
  border-radius: 5px;
}
.sideBar .rs-sidenav-item-focus {
  outline: 0px !important;
}

.sideBar .logoutButtonSmall {
  align-items: center;
  align-self: stretch;
  background-color: rgb(40, 40, 40) !important;
  border-radius: 5px;
  color: var(--collection-1-white);
  display: flex;
  /* font-family: "Roboto-Medium", Helvetica; */
  /* font-size: 16px; */
  /* font-weight: 500; */
  height: 48px;
  justify-content: center;
  letter-spacing: 0;
  /* line-height: 24px; */
  /* padding: 10px 40px; */
  position: relative;
  width: 36px;
  white-space: nowrap;
}

.sideBar .logoutButtonLarge {
  align-items: center;
  align-self: stretch;
  background-color: rgb(40, 40, 40) !important;
  border-radius: 8px;
  color: var(--collection-1-white);
  display: flex;
  font-family: "Roboto-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 24px;
  padding: 10px 40px;
  position: relative;
  width: 255px;
  white-space: nowrap;
}

.sideBar .bottom-content {
  /* padding: 20px; */
  position: absolute;
  bottom: 15px;
}

.sideBar .category-exit-1 {
  margin-right: 10px;
}

.rs-sidenav-subtle .rs-sidenav-item.rs-sidenav-item-active {
  color: white !important;
  background-color: #3b2219;
}

.sideBar .rs-sidenav-subtle .rs-sidenav-item:hover {
  background-color: #3b2219 !important;
}

.sideBar {
  color: grey;
}
.button {
  align-items: center;
  border-radius: 100px;
  display: inline-flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.button .state-layer {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  grid-gap: 8px;
  gap: 8px;
  position: relative;
  width: 100%;
}

.button .icon-2 {
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}

.button .label-text {
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.button .label-text-2 {
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.button.outlined {
  border: 1px solid;
}

.button.tonal.hovered {
  background-color: var(--m3-schemes-secondary-container);
  box-shadow: var(--m3-elevation-light-1);
}

.button.hovered.elevated {
  background-color: var(--m3-schemes-surface-container-low);
  box-shadow: var(--m3-elevation-light-2);
}

.button.focused.filled {
  background-color: var(--m3-schemes-primary);
}

.button.enabled.tonal {
  background-color: var(--m3-schemes-secondary-container);
}

.button.enabled.filled {
  background-color: var(--m3-schemes-primary);
}

.button.pressed.elevated {
  background-color: var(--m3-schemes-surface-container-low);
  box-shadow: var(--m3-elevation-light-1);
}

.button.pressed.tonal {
  background-color: var(--m3-schemes-secondary-container);
}

.button.outlined.pressed {
  border-color: var(--m3-schemes-outline);
}

.button.hovered.filled {
  background-color: var(--m3-schemes-primary);
  box-shadow: var(--m3-elevation-light-1);
}

.button.outlined.focused {
  border-color: var(--m3-schemes-primary);
}

.button.outlined.enabled {
  border-color: var(--m3-schemes-outline);
}

.button.enabled.elevated {
  background-color: var(--m3-schemes-surface-container-low);
  box-shadow: var(--m3-elevation-light-1);
}

.button.outlined.disabled {
  border-color: #1d1b201f;
}

.button.pressed.filled {
  background-color: var(--m3-schemes-primary);
}

.button.outlined.hovered {
  border-color: var(--m3-schemes-outline);
}

.button.focused.tonal {
  background-color: var(--m3-schemes-secondary-container);
}

.button.focused.elevated {
  background-color: var(--m3-schemes-surface-container-low);
  box-shadow: var(--m3-elevation-light-1);
}

.button .state-hovered {
  justify-content: center;
}

.button .style-text {
  justify-content: center;
}

.button .state-pressed {
  justify-content: center;
}

.button .style-filled {
  justify-content: center;
}

.button .show-icon-false {
  justify-content: center;
}

.button .style-tonal {
  justify-content: center;
}

.button .state-enabled {
  justify-content: center;
}

.button .state-focused {
  justify-content: center;
}

.button .style-tonal.state-hovered {
  background-color: #1d192b14;
}

.button .state-hovered.style-elevated {
  background-color: #65558f14;
}

.button .show-icon-true.style-filled {
  padding: 10px 24px 10px 16px;
}

.button .state-focused.style-filled {
  background-color: #ffffff1f;
}

.button .show-icon-false.style-filled {
  padding: 10px 24px;
}

.button .state-pressed.style-elevated {
  background-color: #65558f1f;
}

.button .state-pressed.style-tonal {
  background-color: #1d192b1f;
}

.button .style-outlined.state-pressed {
  background-color: #65558f1f;
}

.button .state-hovered.style-filled {
  background-color: #ffffff14;
}

.button .style-outlined.state-focused {
  background-color: #65558f1f;
}

.button .state-disabled.style-elevated {
  background-color: #1d1b201f;
}

.button .style-tonal.state-disabled {
  background-color: #1d1b201f;
}

.button .show-icon-false.style-outlined {
  padding: 10px 24px;
}

.button .style-outlined.show-icon-true {
  padding: 10px 24px 10px 16px;
}

.button .show-icon-false.style-tonal {
  padding: 10px 24px;
}

.button .show-icon-false.style-elevated {
  padding: 10px 24px;
}

.button .state-focused.style-text {
  background-color: #65558f1f;
}

.button .state-pressed.style-filled {
  background-color: #ffffff1f;
}

.button .style-outlined.state-hovered {
  background-color: #65558f14;
}

.button .show-icon-true.style-elevated {
  padding: 10px 24px 10px 16px;
}

.button .show-icon-true.style-text {
  padding: 10px 16px 10px 12px;
}

.button .state-focused.style-tonal {
  background-color: #1d192b1f;
}

.button .state-focused.style-elevated {
  background-color: #65558f1f;
}

.button .style-text.state-hovered {
  background-color: #65558f14;
}

.button .show-icon-true.style-tonal {
  padding: 10px 24px 10px 16px;
}

.button .state-disabled.style-filled {
  background-color: #1d1b201f;
}

.button .state-pressed.style-text {
  background-color: #65558f1f;
}

.button .show-icon-false.style-text {
  padding: 10px 12px;
}

.button .state-focused.style-filled.show-icon-true {
  border-radius: 4px;
  overflow: hidden;
}

.button .show-icon-true.state-hovered.style-filled {
  border-radius: 4px;
  overflow: hidden;
}

.button .state-layer.state-disabled .label-text {
  color: var(--m3-schemes-on-surface);
  opacity: 0.38;
}

.button .state-layer.style-tonal.state-hovered .label-text {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.state-hovered.style-elevated .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-enabled.style-text .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-focused.style-filled .label-text {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.state-enabled.style-tonal .label-text {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.state-enabled.style-filled .label-text {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.state-pressed.style-elevated .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-pressed.style-tonal .label-text {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.style-outlined.state-pressed .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-hovered.style-filled .label-text {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.style-outlined.state-focused .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.style-outlined.state-enabled .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-enabled.style-elevated .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-focused.style-text .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-pressed.style-filled .label-text {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.style-outlined.state-hovered .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-focused.style-tonal .label-text {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.state-focused.style-elevated .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.style-text.state-hovered .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-pressed.style-text .label-text {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-disabled .label-text-2 {
  color: var(--m3-schemes-on-surface);
  opacity: 0.38;
}

.button .state-layer.style-tonal.state-hovered .label-text-2 {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.state-hovered.style-elevated .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-enabled.style-text .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-focused.style-filled .label-text-2 {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.state-enabled.style-tonal .label-text-2 {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.state-enabled.style-filled .label-text-2 {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.state-pressed.style-elevated .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-pressed.style-tonal .label-text-2 {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.style-outlined.state-pressed .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-hovered.style-filled .label-text-2 {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.style-outlined.state-focused .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.style-outlined.state-enabled .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-enabled.style-elevated .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-focused.style-text .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-pressed.style-filled .label-text-2 {
  color: var(--m3-schemes-on-primary);
}

.button .state-layer.style-outlined.state-hovered .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-focused.style-tonal .label-text-2 {
  color: var(--m3-schemes-on-secondary-container);
}

.button .state-layer.state-focused.style-elevated .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.style-text.state-hovered .label-text-2 {
  color: var(--m3-schemes-primary);
}

.button .state-layer.state-pressed.style-text .label-text-2 {
  color: var(--m3-schemes-primary);
}

.chart-data.series-chart-type {
  height: 192px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1101px;
}

.chart-data.chart-wrapper {
  height: 192px;
}

.chart-data .chart {
  align-items: flex-end;
  display: flex;
  height: 192px;
  justify-content: space-between;
  position: relative;
}

.chart-data .bar {
  align-self: stretch;
  position: relative;
}

.chart-data .chart-bar {
  height: 144px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 48px !important;
  width: 32px !important;
}

.chart-data .chart-bar-2 {
  height: 144px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 48px !important;
  width: 16px !important;
}

.chart-data .chart-bar-wrapper {
  align-self: stretch;
  position: relative;
}

.chart-data .chart-bar-3 {
  height: 176px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 16px !important;
  width: 32px !important;
}

.chart-data .chart-bar-4 {
  height: 176px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 16px !important;
  width: 16px !important;
}

.chart-data .bar-2 {
  align-self: stretch;
  position: relative;
}

.chart-data .chart-bar-5 {
  height: 112px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 80px !important;
  width: 32px !important;
}

.chart-data .chart-bar-6 {
  height: 112px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 80px !important;
  width: 16px !important;
}

.chart-data .bar-3 {
  align-self: stretch;
  position: relative;
}

.chart-data .chart-bar-7 {
  height: 152px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 40px !important;
  width: 32px !important;
}

.chart-data .chart-bar-8 {
  height: 152px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 40px !important;
  width: 16px !important;
}

.chart-data .bar-4 {
  align-self: stretch;
  position: relative;
}

.chart-data .bar-5 {
  align-self: stretch;
  position: relative;
}

.chart-data .chart-bar-9 {
  height: 168px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 24px !important;
  width: 32px !important;
}

.chart-data .chart-bar-10 {
  height: 168px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 24px !important;
  width: 16px !important;
}

.chart-data .bar-6 {
  align-self: stretch;
  position: relative;
}

.chart-data .bar-7 {
  align-self: stretch;
  position: relative;
}

.chart-data .bar-8 {
  align-self: stretch;
  position: relative;
}

.chart-data .bar-9 {
  align-self: stretch;
  position: relative;
}

.chart-data .chart-bar-11 {
  height: 160px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 32px !important;
  width: 32px !important;
}

.chart-data .chart-bar-12 {
  height: 160px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 32px !important;
  width: 16px !important;
}

.chart-data .bar-10 {
  align-self: stretch;
  position: relative;
}

.chart-data .bar-11 {
  align-self: stretch;
  position: relative;
}

.chart-data .chart-bar-13 {
  height: 136px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 56px !important;
  width: 32px !important;
}

.chart-data .chart-bar-14 {
  height: 136px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 56px !important;
  width: 16px !important;
}

.chart-data.bar-desktop {
  width: 1100px;
}

.chart-data.bar-mobile {
  width: 343px;
}

.chart-data.line-wavy {
  width: 1100px;
}

.chart-data.line-volatile {
  width: 1100px;
}

.chart-data.line-gradual {
  width: 1100px;
}

.chart-wrapper.bar-desktop .chart {
  padding: 0px 20px;
  width: 1100px;
}

.chart-wrapper.bar-mobile .chart {
  padding: 0px 12px;
  width: 343px;
}

.chart-wrapper.line-wavy .chart {
  padding: 0px 20px;
  width: 1100px;
}

.chart-wrapper.line-volatile .chart {
  padding: 0px 20px;
  width: 1100px;
}

.chart-wrapper.line-gradual .chart {
  padding: 0px 20px;
  width: 1100px;
}

.chart-wrapper.bar-desktop .bar {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar {
  width: 16px;
}

.chart-wrapper.line-wavy .bar {
  width: 32px;
}

.chart-wrapper.line-volatile .bar {
  width: 32px;
}

.chart-wrapper.line-gradual .bar {
  width: 32px;
}

.chart-wrapper.bar-desktop .chart-bar-wrapper {
  width: 32px;
}

.chart-wrapper.bar-mobile .chart-bar-wrapper {
  width: 16px;
}

.chart-wrapper.line-wavy .chart-bar-wrapper {
  width: 32px;
}

.chart-wrapper.line-volatile .chart-bar-wrapper {
  width: 32px;
}

.chart-wrapper.line-gradual .chart-bar-wrapper {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-2 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-2 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-2 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-2 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-2 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-3 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-3 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-3 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-3 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-3 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-4 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-4 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-4 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-4 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-4 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-5 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-5 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-5 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-5 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-5 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-6 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-6 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-6 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-6 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-6 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-7 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-7 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-7 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-7 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-7 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-8 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-8 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-8 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-8 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-8 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-9 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-9 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-9 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-9 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-9 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-10 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-10 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-10 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-10 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-10 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-11 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-11 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-11 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-11 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-11 {
  width: 32px;
}

.checkboxes {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px;
  position: relative;
}

.checkboxes .state-layer {
  align-items: center;
  border-radius: 100px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 11px;
  position: relative;
}

.checkboxes .container {
  border-radius: 2px;
  height: 18px;
  position: relative;
  width: 18px;
}

.checkboxes .div {
  border-radius: 2px;
  height: 18px;
  position: relative;
  width: 18px;
}

.checkboxes .instance-node {
  height: 24px !important;
  left: 8px !important;
  position: absolute !important;
  top: 8px !important;
  width: 24px !important;
}

.checkboxes .ripple {
  height: 35px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 40px;
}

.checkboxes .container-2 {
  border: 2px solid;
  border-radius: 2px;
  height: 18px;
  position: relative;
  width: 18px;
}

.checkboxes .type-unselected-state-pressed {
  height: 48px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 48px !important;
}

.checkboxes.disabled {
  opacity: 0.38;
}

.checkboxes.pressed {
  border-radius: 100px;
}

.checkboxes .state-pressed {
  overflow: hidden;
}

.checkboxes .state-enabled.error-indeterminate {
  overflow: hidden;
}

.checkboxes .indeterminate.state-hovered {
  background-color: #65558f14;
}

.checkboxes .state-hovered.error-indeterminate {
  background-color: #b3261e14;
  overflow: hidden;
}

.checkboxes .state-focused.error-unselected {
  background-color: #b3261e1f;
  overflow: hidden;
}

.checkboxes .state-pressed.indeterminate {
  background-color: #65558f1f;
}

.checkboxes .state-pressed.error-selected {
  background-color: #b3261e1f;
}

.checkboxes .state-focused.error-indeterminate {
  background-color: #b3261e1f;
  overflow: hidden;
}

.checkboxes .state-hovered.selected {
  background-color: #65558f14;
}

.checkboxes .state-focused.error-selected {
  background-color: #b3261e1f;
}

.checkboxes .state-focused.unselected {
  background-color: #1d1b201f;
}

.checkboxes .state-focused.selected {
  background-color: #65558f1f;
}

.checkboxes .error-selected.state-hovered {
  background-color: #b3261e14;
}

.checkboxes .state-hovered.unselected {
  background-color: #1d1b2014;
}

.checkboxes .state-enabled.error-unselected {
  overflow: hidden;
}

.checkboxes .state-focused.indeterminate {
  background-color: #65558f1f;
}

.checkboxes .state-pressed.error-indeterminate {
  background-color: #b3261e1f;
}

.checkboxes .state-pressed.selected {
  background-color: #65558f1f;
}

.checkboxes .error-unselected.state-hovered {
  background-color: #b3261e14;
  overflow: hidden;
}

.checkboxes .state-layer.state-disabled .container {
  background-color: var(--m3-schemes-on-surface);
}

.checkboxes .state-layer.state-pressed .container {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.state-enabled.error-indeterminate .container {
  background-color: var(--m3-schemes-error);
}

.checkboxes .state-layer.indeterminate.state-hovered .container {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.state-enabled.unselected .container {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.state-hovered.error-indeterminate .container {
  background-color: var(--m3-schemes-error);
}

.checkboxes .state-layer.state-focused.error-unselected .container {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.state-focused.error-indeterminate .container {
  background-color: var(--m3-schemes-error);
}

.checkboxes .state-layer.state-hovered.selected .container {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.state-focused.error-selected .container {
  background-color: var(--m3-schemes-error);
}

.checkboxes .state-layer.error-selected.state-enabled .container {
  background-color: var(--m3-schemes-error);
}

.checkboxes .state-layer.state-focused.unselected .container {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.state-enabled.indeterminate .container {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.state-enabled.selected .container {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.state-focused.selected .container {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.error-selected.state-hovered .container {
  background-color: var(--m3-schemes-error);
}

.checkboxes .state-layer.state-hovered.unselected .container {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.state-enabled.error-unselected .container {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.state-focused.indeterminate .container {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.error-unselected.state-hovered .container {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.error-indeterminate .div {
  background-color: var(--m3-schemes-error);
}

.checkboxes .state-layer.error-unselected .div {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.error-selected .div {
  background-color: var(--m3-schemes-error);
}

.checkboxes .state-layer.unselected .div {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.indeterminate .div {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.selected .div {
  background-color: var(--m3-schemes-primary);
}

.checkboxes .state-layer.error-indeterminate .container-2 {
  border-color: var(--m3-schemes-on-surface-variant);
}

.checkboxes .state-layer.state-pressed .container-2 {
  border-color: var(--m3-schemes-on-surface-variant);
}

.checkboxes .state-layer.selected .container-2 {
  border-color: var(--m3-schemes-on-surface-variant);
}

.checkboxes .state-layer.error-selected .container-2 {
  border-color: var(--m3-schemes-on-surface-variant);
}

.checkboxes .state-layer.indeterminate .container-2 {
  border-color: var(--m3-schemes-on-surface-variant);
}

.checkboxes .state-layer.state-enabled.unselected .container-2 {
  border-color: var(--m3-schemes-on-surface-variant);
}

.checkboxes .state-layer.state-focused.error-unselected .container-2 {
  border-color: var(--m3-schemes-error);
}

.checkboxes .state-layer.error-unselected.state-disabled .container-2 {
  border-color: var(--m3-schemes-on-surface-variant);
}

.checkboxes .state-layer.state-focused.unselected .container-2 {
  border-color: var(--m3-schemes-on-surface);
}

.checkboxes .state-layer.unselected.state-disabled .container-2 {
  border-color: var(--m3-schemes-on-surface);
}

.checkboxes .state-layer.state-hovered.unselected .container-2 {
  border-color: var(--m3-schemes-on-surface);
}

.checkboxes .state-layer.state-enabled.error-unselected .container-2 {
  border-color: var(--m3-schemes-error);
}

.checkboxes .state-layer.error-unselected.state-hovered .container-2 {
  border-color: var(--m3-schemes-error);
}

.chevron-down {
  height: 24px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 24px !important;
}

.chevron-up {
  background-image: url(/icon-66.svg);
  background-size: 100% 100%;
  height: 24px;
  position: relative;
  width: 24px;
}

.currency {
  align-items: center;
  border-radius: var(--shape-corner-extra-small);
  grid-gap: 4px;
  gap: 4px;
  position: relative;
}

.currency .money-pound {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.currency .frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  padding: 4px;
  position: relative;
}

.currency .text-wrapper-3 {
  color: var(--collection-1-black);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.currency .money-pound-wrapper {
  align-items: center;
  background-color: var(--collection-1-accent-20);
  border-radius: var(--shape-corner-extra-small);
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: 10px;
  gap: 10px;
  padding: 8px;
  position: relative;
}

.currency.property-1-variant-4 {
  display: inline-flex;
}

.currency.property-1-variant-5 {
  background-color: var(--collection-1-accent-10);
  border: 1px solid;
  border-color: #f8f8f833;
  display: inline-flex;
  padding: 0px 8px 0px 0px;
}

.currency.property-1-icon {
  border: 1px solid;
  border-color: #f8f8f833;
  display: inline-flex;
  padding: 0px 8px 0px 0px;
}

.currency.property-1-hover {
  background-color: var(--collection-1-accent-10);
  display: flex;
  width: 118px;
}

.currency.property-1-default {
  display: flex;
  width: 118px;
}

.div-wrapper {
  align-items: center;
  background-color: var(--collection-1-accent-20);
  border-radius: var(--shape-corner-extra-small);
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  height: 20px;
  justify-content: center;
  padding: 4px;
  position: relative;
  width: 20px;
}

.div-wrapper .ph-copy-duotone {
  height: 16px !important;
  margin-bottom: -2px !important;
  margin-left: -2px !important;
  margin-right: -2px !important;
  margin-top: -2px !important;
  position: relative !important;
  width: 16px !important;
}

.finalised {
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  height: 32px;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.finalised .label-text-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: 8px;
  gap: 8px;
  height: 32px;
  justify-content: center;
  padding: 6px 16px;
  position: relative;
}

.finalised .label-text-3 {
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.finalised.finalised-false {
  background-color: rgb(249, 164, 164);
  color: red;
}

.finalised.finalised-true {
  background-color: lightgreen;
  color: green;
}

.finalised.finalised-false .label-text-3 {
  /* color: var(--m3-schemes-on-error) !important; */
}

.finalised.finalised-true .label-text-3 {
  /* color: var(--color-text-positive-on-positive-secondary) !important; */
}

.frame-wrapper {
  align-items: center;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  padding: var(--size-space-200) var(--size-space-100) var(--size-space-200) var(--size-space-100);
  position: relative;
  width: 370px;
}

.frame-wrapper .amount-sent {
  color: var(--m3-schemes-outline);
  flex: 1 1;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 20px;
  position: relative;
}

.frame-wrapper .element-USD {
  color: #ffffff;
  flex: 1 1;
  font-family: var(--m3-title-medium-font-family);
  font-size: var(--m3-title-medium-font-size);
  font-style: var(--m3-title-medium-font-style);
  font-weight: var(--m3-title-medium-font-weight);
  letter-spacing: var(--m3-title-medium-letter-spacing);
  line-height: var(--m3-title-medium-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
}

.home {
  background-image: url(/icon-157.svg);
  background-size: 100% 100%;
  height: 24px;
  position: relative;
  width: 24px;
}

.icon.instance-node {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.icon.graph-icon-wrapper {
  height: 24px;
  position: relative;
  width: 24px;
}

.icon .overlap-group-wrapper {
  position: relative;
}

.icon .overlap-group {
  position: relative;
}

.icon .vector-shape {
  height: 17px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 17px;
}

.icon .img {
  height: 9px;
  left: 11px;
  position: absolute;
  top: 0;
  width: 9px;
}

.icon .div {
  border-radius: 4px;
  height: 22px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 22px;
}

.icon .text {
  color: var(--token-design-1-text-colors-text-colors-white);
  font-family: var(--semibold-16px-font-family);
  font-size: var(--semibold-16px-font-size);
  font-style: var(--semibold-16px-font-style);
  font-weight: var(--semibold-16px-font-weight);
  height: 24px;
  left: 6px;
  letter-spacing: var(--semibold-16px-letter-spacing);
  line-height: var(--semibold-16px-line-height);
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.icon .sort {
  height: 24px;
  left: 1px;
}

.icon .check {
  height: 24px;
  left: 1px;
}

.icon .noti-01 {
  height: 24px;
  left: 1px;
}

.icon .posts-icon {
  height: 24px;
  left: 1px;
}

.icon .user {
  height: 24px;
  left: 1px;
}

.icon .filter-1 {
  height: 24px;
  left: 1px;
}

.icon .exit {
  height: 24px;
  left: 1px;
}

.icon .moon-icon {
  height: 24px;
  left: 1px;
}

.icon .sort-2 {
  height: 24px;
  left: 1px;
}

.icon .sort-3 {
  height: 24px;
  left: 1px;
}

.icon .send-icon {
  height: 24px;
  left: 1px;
}

.icon .add-icon {
  height: 24px;
  left: 1px;
}

.icon .transaction-list {
  height: 24px;
  left: 1px;
}

.icon .up-arrow-icon {
  height: 24px;
  left: 1px;
}

.icon .filter {
  height: 24px;
  left: 1px;
}

.icon .sort-1 {
  height: 24px;
  left: 1px;
}

.icon .noti-02 {
  height: 24px;
  left: 1px;
}

.icon .home-icon {
  height: 24px;
  left: 1px;
}

.icon .list {
  height: 24px;
  left: 1px;
}

.icon .settings {
  height: 24px;
  left: 1px;
}

.icon .clarity-code-line {
  height: 24px;
  left: 1px;
}

.icon .chat-icon {
  height: 24px;
  left: 1px;
}

.icon .audience-icon {
  height: 24px;
  left: 1px;
}

.icon .sun-icon {
  height: 24px;
  left: 1px;
}

.icon .schedule-icon {
  height: 24px;
  left: 1px;
}

.icon .sign {
  height: 24px;
  left: 1px;
}

.icon .exchange {
  height: 24px;
  left: 1px;
}

.icon .add-circle-icon {
  height: 24px;
  left: 1px;
}

.icon .heart-icon {
  height: 24px;
  left: 1px;
}

.icon .alert {
  height: 24px;
  left: 1px;
}

.icon .mingcute-search-line {
  height: 24px;
  left: 1px;
}

.icon .down-arrow-icon {
  height: 24px;
  left: 1px;
}

.icon .notification-icon {
  height: 24px;
  left: 1px;
}

.icon .graph-icon {
  height: 18px;
  left: 3px;
  top: 3px;
  width: 19px;
}

.icon .discount-icon {
  height: 24px;
  left: 1px;
}

.icon .overlap-group-wrapper.sort .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.check .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.noti-01 .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.posts-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.user .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.filter-1 .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.exit .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.moon-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.sort-2 .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.sort-3 .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.send-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.add-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.transaction-list .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.up-arrow-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.filter .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.sort-1 .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.noti-02 .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.home-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.list .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.settings .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.clarity-code-line .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.chat-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.audience-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.sun-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.schedule-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.sign .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.exchange .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.add-circle-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.heart-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.alert .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.mingcute-search-line .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.down-arrow-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.notification-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.graph-icon .overlap-group {
  height: 20px;
  left: -1px;
  top: -1px;
  width: 20px;
}

.icon .overlap-group-wrapper.discount-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.sort .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.check .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.noti-01 .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.posts-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.user .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.filter-1 .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.exit .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.moon-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.sort-2 .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.sort-3 .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.send-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.add-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.transaction-list .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.up-arrow-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.filter .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.sort-1 .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.noti-02 .div {
  background-color: var(--primary-colors-blue-color);
}

.icon .overlap-group-wrapper.home-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.list .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.settings .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.clarity-code-line .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.chat-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.audience-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.sun-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.schedule-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.sign .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.exchange .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.add-circle-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.heart-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.alert .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.mingcute-search-line .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.down-arrow-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.notification-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.graph-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.discount-icon .div {
  background-color: var(--collection-1-accent);
}

.icons-interaction {
  align-items: center;
  border-radius: var(--shape-corner-small);
  display: inline-flex;
  grid-gap: 10px;
  gap: 10px;
  padding: 8px;
  position: relative;
}

.icons-interaction.property-1-0-hover {
  background-color: var(--collection-1-accent-20);
}

.icons-interaction.property-1-0-selected {
  background-color: var(--collection-1-accent-20);
}

.options {
  background-image: url(/system-uicons-menu-vertical-3.svg);
  background-size: 100% 100%;
  border-radius: var(--shape-corner-extra-small);
  height: 24px;
  width: 24px;
}

.options:hover {
  background-image: url(/system-uicons-menu-vertical-4.svg);
}

.property-default {
  align-items: center;
  background-color: #181818;
  border: 1px solid;
  border-color: #ffffff33;
  border-radius: var(--shape-corner-small);
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  height: 40px;
  max-width: 720px;
  overflow: hidden;
  padding: 12px 0px 12px 12px;
  position: relative;
  width: 553px;
}

.property-default .content {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  grid-gap: 10px;
  gap: 10px;
  position: relative;
}

.property-default .supporting-text {
  color: var(--collection-1-white);
  font-family: var(--m3-body-large-font-family);
  font-size: var(--m3-body-large-font-size);
  font-style: var(--m3-body-large-font-style);
  font-weight: var(--m3-body-large-font-weight);
  letter-spacing: var(--m3-body-large-letter-spacing);
  line-height: var(--m3-body-large-line-height);
  margin-bottom: -3px;
  margin-top: -5px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.property-default .icons {
  align-items: center;
  background-color: var(--collection-1-white);
  border-radius: 0px var(--shape-corner-small) var(--shape-corner-small) 0px;
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  height: 48px;
  justify-content: center;
  margin-bottom: -16px;
  margin-top: -16px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  width: 48px;
}

.property-default .icon-57 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.property-default-wrapper {
  background-image: url(/icon-111.svg);
  background-size: 100% 100%;
  height: 24px;
  width: 24px;
}

.building-blocks {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 4px 0px;
  position: relative;
  width: 108px;
}

.building-blocks .container {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-radius: 100px 0px 0px 100px;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.building-blocks .state-layer {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  grid-gap: 8px;
  gap: 8px;
  justify-content: center;
  padding: 10px 12px;
  position: relative;
}

.building-blocks .instance-node-2 {
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}

.building-blocks .label-text {
  color: var(--m3-schemes-on-surface);
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.building-blocks .label-text-2 {
  color: var(--m3-schemes-on-secondary-container);
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.building-blocks.hovered {
  flex-direction: column;
}

.building-blocks.pressed {
  flex-direction: column;
}

.building-blocks.focused {
  flex-direction: column;
}

.building-blocks .state-hovered {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  width: 100%;
}

.building-blocks .selected-true {
  background-color: var(--m3-schemes-secondary-container);
}

.building-blocks .state-pressed {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  width: 100%;
}

.building-blocks .state-disabled {
  border-color: #1d1b201f;
}

.building-blocks .label-only {
  flex-direction: column;
}

.building-blocks .state-enabled {
  border-color: var(--m3-schemes-outline);
}

.building-blocks .state-focused {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  width: 100%;
}

.building-blocks .label-icon {
  flex-direction: column;
}

.building-blocks .state-enabled.selected-true {
  flex-direction: column;
}

.building-blocks .container.state-hovered .state-layer {
  width: 100%;
}

.building-blocks .container.state-pressed .state-layer {
  width: 100%;
}

.building-blocks .container.label-only .state-layer {
  width: 100%;
}

.building-blocks .container.state-focused .state-layer {
  width: 100%;
}

.building-blocks .container.label-icon .state-layer {
  width: 100%;
}

.building-blocks .container.selected-true.state-hovered .state-layer {
  background-color: #1d192b14;
}

.building-blocks .container.selected-false.state-hovered .state-layer {
  background-color: #1d1b2014;
}

.building-blocks .container.state-pressed.selected-true .state-layer {
  background-color: #1d192b1f;
}

.building-blocks .container.state-focused.selected-true .state-layer {
  background-color: #1d192b1f;
}

.building-blocks .container.state-focused.selected-false .state-layer {
  background-color: #1d1b201f;
}

.building-blocks .container.state-enabled.selected-true .state-layer {
  width: 100%;
}

.building-blocks .container.state-pressed.selected-false .state-layer {
  background-color: #1d1b201f;
}

.building-blocks .container.state-disabled .label-text {
  opacity: 0.38;
}

.building-blocks-segmented-button-button-segment-end {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 4px 0px;
  position: relative;
  width: 108px;
}

.building-blocks-segmented-button-button-segment-end .container-2 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-radius: 0px 100px 100px 0px;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.building-blocks-segmented-button-button-segment-end .state-layer-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  grid-gap: 8px;
  gap: 8px;
  justify-content: center;
  padding: 10px 12px;
  position: relative;
}

.building-blocks-segmented-button-button-segment-end .icon-98 {
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}

.building-blocks-segmented-button-button-segment-end .label-text-4 {
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.building-blocks-segmented-button-button-segment-end.state-4-hovered {
  flex-direction: column;
}

.building-blocks-segmented-button-button-segment-end.state-4-pressed {
  flex-direction: column;
}

.building-blocks-segmented-button-button-segment-end.state-4-focused {
  flex-direction: column;
}

.building-blocks-segmented-button-button-segment-end .state-5-hovered {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .selected-4-true {
  background-color: var(--m3-schemes-secondary-container);
}

.building-blocks-segmented-button-button-segment-end .state-5-pressed {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .state-5-disabled {
  border-color: #1d1b201f;
}

.building-blocks-segmented-button-button-segment-end .configuration-2-label-only {
  flex-direction: column;
}

.building-blocks-segmented-button-button-segment-end .state-5-enabled {
  border-color: var(--m3-schemes-outline);
  grid-gap: 10px;
  gap: 10px;
}

.building-blocks-segmented-button-button-segment-end .state-5-focused {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .configuration-2-label-icon {
  flex-direction: column;
}

.building-blocks-segmented-button-button-segment-end .state-5-enabled.selected-4-true {
  flex-direction: column;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-hovered .state-layer-3 {
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-pressed .state-layer-3 {
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .container-2.configuration-2-label-only .state-layer-3 {
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-focused .state-layer-3 {
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .container-2.configuration-2-label-icon .state-layer-3 {
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .container-2.selected-4-true.state-5-hovered .state-layer-3 {
  background-color: #1d192b14;
}

.building-blocks-segmented-button-button-segment-end .container-2.selected-4-false.state-5-hovered .state-layer-3 {
  background-color: #1d1b2014;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-pressed.selected-4-true .state-layer-3 {
  background-color: #1d192b1f;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-focused.selected-4-true .state-layer-3 {
  background-color: #1d192b1f;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-focused.selected-4-false .state-layer-3 {
  background-color: #1d1b201f;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-enabled.selected-4-true .state-layer-3 {
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-pressed.selected-4-false .state-layer-3 {
  background-color: #1d1b201f;
}

.building-blocks-segmented-button-button-segment-end .container-2.selected-4-false .label-text-4 {
  color: var(--m3-schemes-on-surface);
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-disabled .label-text-4 {
  opacity: 0.38;
}

.building-blocks-segmented-button-button-segment-end .container-2.selected-4-true .label-text-4 {
  color: var(--m3-schemes-on-secondary-container);
}

.configuration-icon-wrapper {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 4px 0px;
  position: relative;
  width: 108px;
}

.configuration-icon-wrapper .state-layer-wrapper {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.configuration-icon-wrapper .state-layer-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  grid-gap: 8px;
  gap: 8px;
  justify-content: center;
  padding: 10px 12px;
  position: relative;
}

.configuration-icon-wrapper .icon-88 {
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}

.configuration-icon-wrapper .label-text-3 {
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.configuration-icon-wrapper.state-1-hovered {
  flex-direction: column;
}

.configuration-icon-wrapper.state-1-pressed {
  flex-direction: column;
}

.configuration-icon-wrapper.state-1-focused {
  flex-direction: column;
}

.configuration-icon-wrapper .state-2-hovered {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  width: 100%;
}

.configuration-icon-wrapper .selected-1-true {
  background-color: var(--m3-schemes-secondary-container);
}

.configuration-icon-wrapper .state-2-pressed {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  width: 100%;
}

.configuration-icon-wrapper .state-2-disabled {
  border-color: #1d1b201f;
}

.configuration-icon-wrapper .configuration-0-label-only {
  flex-direction: column;
}

.configuration-icon-wrapper .state-2-enabled {
  border-color: var(--m3-schemes-outline);
}

.configuration-icon-wrapper .state-2-focused {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  width: 100%;
}

.configuration-icon-wrapper .configuration-0-label-icon {
  flex-direction: column;
}

.configuration-icon-wrapper .state-2-enabled.selected-1-true {
  flex-direction: column;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-hovered .state-layer-2 {
  width: 100%;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-pressed .state-layer-2 {
  width: 100%;
}

.configuration-icon-wrapper .state-layer-wrapper.configuration-0-label-only .state-layer-2 {
  width: 100%;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-focused .state-layer-2 {
  width: 100%;
}

.configuration-icon-wrapper .state-layer-wrapper.configuration-0-label-icon .state-layer-2 {
  width: 100%;
}

.configuration-icon-wrapper .state-layer-wrapper.selected-1-true.state-2-hovered .state-layer-2 {
  background-color: #1d192b14;
}

.configuration-icon-wrapper .state-layer-wrapper.selected-1-false.state-2-hovered .state-layer-2 {
  background-color: #1d1b2014;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-pressed.selected-1-true .state-layer-2 {
  background-color: #1d192b1f;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-focused.selected-1-true .state-layer-2 {
  background-color: #1d192b1f;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-focused.selected-1-false .state-layer-2 {
  background-color: #1d1b201f;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-enabled.selected-1-true .state-layer-2 {
  width: 100%;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-pressed.selected-1-false .state-layer-2 {
  background-color: #1d1b201f;
}

.configuration-icon-wrapper .state-layer-wrapper.selected-1-false .label-text-3 {
  color: var(--m3-schemes-on-surface);
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-disabled .label-text-3 {
  opacity: 0.38;
}

.configuration-icon-wrapper .state-layer-wrapper.selected-1-true .label-text-3 {
  color: var(--m3-schemes-on-secondary-container);
}

.property-segmented-wrapper {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.property-segmented-wrapper .class {
  color: var(--collection-1-white) !important;
}

.property-segmented-wrapper .class-2 {
  border-color: var(--collection-1-accent-20) !important;
}

.property-segmented-wrapper .class-3 {
  background-color: var(--collection-1-accent-20) !important;
  border-color: var(--collection-1-accent-10) !important;
}

.property-segmented-wrapper .class-4 {
  background-color: var(--collection-1-accent-20) !important;
  border-color: var(--collection-1-accent-20) !important;
}

.property-segmented-wrapper .class-5 {
  color: var(--collection-1-accent) !important;
}

.property-segmented-wrapper.hover {
  flex-direction: column;
}

.property-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.property-wrapper .class-2 {
  background-color: var(--collection-1-accent-50) !important;
}

.property-wrapper .checkboxes-instance {
  padding: 4px !important;
}

.property-wrapper .checkboxes-2 {
  flex: 0 0 auto !important;
  padding: unset !important;
}

.property-wrapper .class-3 {
  height: 24px !important;
  left: 1px !important;
  position: absolute !important;
  top: 1px !important;
  width: 24px !important;
}

.property-wrapper .class-4 {
  border-color: var(--collection-1-accent-50) !important;
}

.send {
  background-image: url(/icon-45.svg);
  background-size: 100% 100%;
  height: 24px;
  position: relative;
  width: 24px;
}

.swap {
  background-image: url(/icon-173.svg);
  background-size: 100% 100%;
  height: 24px;
  position: relative;
  width: 24px;
}

.x-axis {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.x-axis .element {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .feb {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .mar {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .element-2 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .element-3 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .element-4 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .element-5 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .aug {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .sep {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .element-6 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .element-7 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .element-8 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .jan {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .mar-2 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .may {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .jul {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .sep-2 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .nov {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .dec {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .text-wrapper-4 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .text-wrapper-5 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .text-wrapper-6 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis.mobile {
  padding: 0px 8px;
  width: 342px;
}

.x-axis.desktop {
  padding: 0px 24px;
  width: 1216px;
}

.x-axis .breakpoint-desktop {
  text-align: center;
}

.x-axis .twelve-months {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .breakpoint-mobile {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .thirty-days.breakpoint-desktop {
  width: 16px;
}

.x-axis .breakpoint-desktop.seven-days {
  width: 16px;
}

.x-axis .breakpoint-0-desktop {
  text-align: center;
}

.x-axis .data-twelve-months {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .breakpoint-0-mobile {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .data-seven-days {
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .data-thirty-days.breakpoint-0-desktop {
  width: 16px;
}

.x-axis .breakpoint-1-desktop {
  text-align: center;
}

.x-axis .data-0-twelve-months {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .breakpoint-1-mobile {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .data-0-seven-days {
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .data-0-thirty-days.breakpoint-1-desktop {
  width: 16px;
}

.x-axis .breakpoint-2-desktop {
  text-align: center;
}

.x-axis .data-1-twelve-months {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .breakpoint-2-mobile {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .data-1-thirty-days.breakpoint-2-desktop {
  width: 16px;
}

.x-axis .breakpoint-2-desktop.data-1-seven-days {
  width: 16px;
}

.x-axis .breakpoint-3-desktop {
  text-align: center;
}

.x-axis .data-2-twelve-months {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .breakpoint-3-mobile {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .data-2-thirty-days.breakpoint-3-desktop {
  width: 16px;
}

.x-axis .breakpoint-3-desktop.data-2-seven-days {
  width: 16px;
}

.x-axis .breakpoint-4-desktop {
  text-align: center;
}

.x-axis .data-3-twelve-months {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .breakpoint-4-mobile {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .data-3-thirty-days.breakpoint-4-desktop {
  width: 16px;
}

.x-axis .breakpoint-4-desktop.data-3-seven-days {
  width: 16px;
}

.x-axis .breakpoint-5-desktop {
  text-align: center;
}

.x-axis .data-4-twelve-months {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .breakpoint-5-mobile {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .data-4-thirty-days.breakpoint-5-desktop {
  width: 16px;
}

.x-axis .breakpoint-5-desktop.data-4-seven-days {
  width: 16px;
}

.x-axis .breakpoint-6-desktop {
  text-align: center;
}

.x-axis .data-5-twelve-months {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .breakpoint-6-mobile {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .data-5-seven-days {
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .data-5-thirty-days.breakpoint-6-desktop {
  width: 16px;
}

.x-axis .breakpoint-7-desktop {
  text-align: center;
}

.x-axis .data-6-twelve-months {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .breakpoint-7-mobile {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .data-6-seven-days {
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .data-6-thirty-days.breakpoint-7-desktop {
  width: 16px;
}

.x-axis .breakpoint-8-desktop {
  text-align: center;
}

.x-axis .data-7-twelve-months {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .breakpoint-8-mobile {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .data-7-thirty-days.breakpoint-8-desktop {
  width: 16px;
}

.x-axis .breakpoint-8-desktop.data-7-seven-days {
  width: 16px;
}

.x-axis .breakpoint-9-desktop {
  text-align: center;
}

.x-axis .data-8-twelve-months {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .breakpoint-9-mobile {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .data-8-thirty-days.breakpoint-9-desktop {
  width: 16px;
}

.x-axis .breakpoint-9-desktop.data-8-seven-days {
  width: 16px;
}

.x-axis .breakpoint-10-desktop {
  text-align: center;
}

.x-axis .data-9-twelve-months {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .breakpoint-10-mobile {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis .data-9-thirty-days.breakpoint-10-desktop {
  width: 16px;
}

.x-axis .breakpoint-10-desktop.data-9-seven-days {
  width: 16px;
}

.x-axis.mobile .jan {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis.desktop .jan {
  text-align: center;
  width: 56px;
}

.x-axis.mobile .mar-2 {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis.desktop .mar-2 {
  text-align: center;
  width: 56px;
}

.x-axis.mobile .may {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis.desktop .may {
  text-align: center;
  width: 56px;
}

.x-axis.mobile .jul {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis.desktop .jul {
  text-align: center;
  width: 56px;
}

.x-axis.mobile .sep-2 {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis.desktop .sep-2 {
  text-align: center;
  width: 56px;
}

.x-axis.mobile .nov {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis.desktop .nov {
  text-align: center;
  width: 56px;
}

.x-axis.mobile .dec {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis.desktop .dec {
  text-align: center;
  width: 56px;
}

.x-axis.mobile .text-wrapper-4 {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis.desktop .text-wrapper-4 {
  text-align: center;
  width: 16px;
}

.x-axis.mobile .text-wrapper-5 {
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.x-axis.desktop .text-wrapper-5 {
  text-align: center;
  width: 16px;
}

.x-axis.mobile .text-wrapper-6 {
  text-align: right;
}

.x-axis.desktop .text-wrapper-6 {
  text-align: center;
}

.create {
  background-color: #0e0f11;
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  width: 100%;
  height: 100vh;
}

.create .overlap-wrapper {
  background-color: #0e0f11;
  height: 1040px;
  /* width: 1512px; */
}

.create .overlap {
  background-image: url(/image-17.png);
  background-position: 50% 50%;
  background-size: cover;
}

.create .frame-2 {
  align-items: flex-start;
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  height: 1040px;
  position: relative;
  width: 100%;
}

.create .design-component-instance-node-2 {
  flex: 0 0 auto !important;
}

.create .side-bar-instance {
  background-color: var(--collection-1-accent-20) !important;
}

.create .side-bar-2 {
  font-family: var(--semibold-16px-font-family) !important;
  font-size: var(--semibold-16px-font-size) !important;
  font-style: var(--semibold-16px-font-style) !important;
  font-weight: var(--semibold-16px-font-weight) !important;
  letter-spacing: var(--semibold-16px-letter-spacing) !important;
  line-height: var(--semibold-16px-line-height) !important;
}

.create .frame-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-grow: 1;
  grid-gap: 24px;
  gap: 24px;
  padding: 0px 24px;
  position: relative;
}

.create .header {
  align-items: center;
  align-self: stretch;
  background-color: transparent;
  border-radius: var(--shape-corner-small);
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 8px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.create .frame-4 {
  align-items: center;
  /* background-color: var(--collection-1-black); */
  border-radius: var(--shape-corner-small);
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: var(--size-space-200);
  gap: var(--size-space-200);
  overflow: hidden;
  padding: 8px;
  position: relative;
}

.create .currency-instance {
  flex: 0 0 auto !important;
  margin-bottom: -1px !important;
  margin-left: -1px !important;
  margin-top: -1px !important;
}

.create .currency-2 {
  background-image: url(/icon-182.svg) !important;
  height: 16px !important;
  width: 16px !important;
}

.create .icon-183-wrapper {
  align-items: center;
  background-color: var(--collection-1-accent-5);
  border-radius: var(--radius-8px);
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: 8px;
  gap: 8px;
  justify-content: center;
  overflow: hidden;
  padding: 8px;
  position: relative;
}

.create .icon-instance-node-2 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.create .icons-2 {
  align-items: center;
  border-radius: var(--radius-8px);
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: 12px;
  gap: 12px;
  justify-content: center;
  overflow: hidden;
  padding: 8px;
  position: relative;
}

.create .icon-116-wrapper {
  align-items: center;
  background-color: var(--collection-1-accent-20);
  border-radius: var(--shape-corner-extra-large);
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: 12px;
  gap: 12px;
  justify-content: center;
  overflow: hidden;
  padding: 8px;
  position: relative;
}

.create .frame-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-grow: 1;
  grid-gap: 24px;
  gap: 24px;
  overflow-y: scroll;
  padding: 0px 0px 40px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.create .frame-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  grid-gap: 20px;
  gap: 20px;
  position: relative;
  width: 100%;
}

.create .transactions-table {
  align-items: flex-start;
  background-color: var(--collection-1-black);
  border: 1px solid;
  border-color: #ffffff33;
  border-radius: var(--shape-corner-large);
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-grow: 1;
  grid-gap: var(--size-space-400);
  gap: var(--size-space-400);
  overflow: hidden;
  padding: 24px;
  position: relative;
  height: 90px;
  /* margin-left: 9px; */
}

.create .frame-7 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 48px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.create .title {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 4px 8px;
  position: relative;
  width: 100%;
}

.create .frame-8 {
  align-items: center;
  display: flex;
  grid-gap: var(--size-space-400);
  gap: var(--size-space-400);
  position: relative;
  width: 375px;
}

.create .text-wrapper-5 {
  color: #cac4d0;
  font-family: var(--subheading-font-family);
  font-size: var(--subheading-font-size);
  font-style: var(--subheading-font-style);
  font-weight: var(--subheading-font-weight);
  letter-spacing: var(--subheading-letter-spacing);
  line-height: var(--subheading-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create .frame-9 {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  grid-gap: var(--size-space-400);
  gap: var(--size-space-400);
  justify-content: flex-end;
  position: relative;
}

.create .icon-198-wrapper {
  align-items: center;
  background-color: var(--collection-1-accent-10);
  border-radius: var(--radius-8px);
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: 12px;
  gap: 12px;
  justify-content: center;
  overflow: hidden;
  padding: 8px;
  position: relative;
}

.create .frame-10 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--collection-1-BG-2);
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  grid-gap: var(--size-space-400);
  gap: var(--size-space-400);
  padding: 16px;
  position: relative;
  width: 100%;
}

.create .frame-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  grid-gap: var(--size-space-200);
  gap: var(--size-space-200);
  position: relative;
  width: 100%;
}

.create .frame-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.create .frame-12 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--shape-corner-small);
  display: flex;
  flex: 0 0 auto;
  grid-gap: var(--size-space-100);
  gap: var(--size-space-100);
  padding: var(--size-space-400) 0px var(--size-space-400) 0px;
  position: relative;
  width: 100%;
}

.create .participant-wrapper {
  align-items: center;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  position: relative;
  width: 92px;
}

.create .participant {
  color: #cac4d0;
  font-family: var(--m3-title-medium-font-family);
  font-size: var(--m3-title-medium-font-size);
  font-style: var(--m3-title-medium-font-style);
  font-weight: var(--m3-title-medium-font-weight);
  letter-spacing: var(--m3-title-medium-letter-spacing);
  line-height: var(--m3-title-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create .frame-13 {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  grid-gap: var(--size-space-200);
  gap: var(--size-space-200);
  position: relative;
}

.create .text-field-3 {
  align-items: flex-start;
  border-radius: var(--shape-corner-small) var(--shape-corner-small)
    var(--shape-corner-none) var(--shape-corner-none);
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-grow: 1;
  height: 56px;
  position: relative;
}

.create .text-field-4 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--m3-state-layers-on-secondary-opacity-08);
  border-radius: var(--shape-corner-small) var(--shape-corner-small)
    var(--shape-corner-none) var(--shape-corner-none);
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-grow: 1;
  grid-gap: 10px;
  gap: 10px;
  position: relative;
  width: 100%;
}

.create .state-layer-5 {
  align-items: center;
  align-self: stretch;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  padding: 4px var(--size-space-200) 4px 16px;
  position: relative;
  width: 100%;
}

.create .content-3 {
  align-items: flex-start;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-grow: 1;
  height: 48px;
  justify-content: center;
  padding: 4px 0px;
  position: relative;
}

.create .div-wrapper-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.create .label-text-6 {
  color: var(--m3-schemes-outline);
  font-family: var(--m3-body-small-font-family);
  font-size: var(--m3-body-small-font-size);
  font-style: var(--m3-body-small-font-style);
  font-weight: var(--m3-body-small-font-weight);
  letter-spacing: var(--m3-body-small-letter-spacing);
  line-height: var(--m3-body-small-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create .input-text-wrapper {
  align-items: center;
  background: none;
  border: none;
  color: var(--m-3white);
  display: inline-flex;
  flex: 0 0 auto;
  font-family: var(--m3-body-large-font-family);
  font-size: var(--m3-body-large-font-size);
  font-style: var(--m3-body-large-font-style);
  font-weight: var(--m3-body-large-font-weight);
  letter-spacing: var(--m3-body-large-letter-spacing);
  line-height: var(--m3-body-large-line-height);
  margin-top: -1px;
  padding: 0;
  position: relative;
  white-space: nowrap;
}

.create .sort-instance {
  background-image: url(/icon-55.svg) !important;
  height: 16px !important;
  width: 16px !important;
}

.create .active-indicator-2 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.create .swap-2 {
  background-image: url(/icon-56.svg) !important;
}

.create .text-field-5 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--m3-state-layers-on-primary-opacity-08);
  border-radius: var(--shape-corner-small) var(--shape-corner-small) 0px 0px;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-grow: 1;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.create .state-layer-6 {
  align-items: center;
  align-self: stretch;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  padding: 4px 8px 4px 16px;
  position: relative;
  width: 100%;
}

.create .placeholder-text-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: 10px;
  gap: 10px;
  position: relative;
}

.create .placeholder-text-3 {
  color: var(--m3-schemes-outline-variant);
  font-family: var(--m3-body-large-font-family);
  font-size: var(--m3-body-large-font-size);
  font-style: var(--m3-body-large-font-style);
  font-weight: var(--m3-body-large-font-weight);
  letter-spacing: var(--m3-body-large-letter-spacing);
  line-height: var(--m3-body-large-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create .sort-2-instance {
  background-image: url(/icon-57.svg) !important;
  height: 16px !important;
  width: 16px !important;
}

.create .line {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.create .frame-14 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  position: relative;
  width: 100%;
}

.create .frame-15 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: 10px;
  gap: 10px;
  position: relative;
}

.create .text-wrapper-6 {
  color: #cac4d0;
  font-family: "Roboto", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create .frame-16 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  grid-gap: var(--size-space-200);
  gap: var(--size-space-200);
  position: relative;
  width: 100%;
}

.create .frame-17 {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  grid-gap: 8px;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.create .input-text-2 {
  color: var(--m-3white);
  font-family: var(--m3-body-large-font-family);
  font-size: var(--m3-body-large-font-size);
  font-style: var(--m3-body-large-font-style);
  font-weight: var(--m3-body-large-font-weight);
  letter-spacing: var(--m3-body-large-letter-spacing);
  line-height: var(--m3-body-large-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create .mini-potfolio-card {
  align-items: flex-start;
  background-color: var(--collection-1-black);
  border: 1px solid;
  border-color: #ffffff33;
  border-radius: var(--shape-corner-small);
  display: flex;
  flex-direction: column;
  grid-gap: var(--size-space-400);
  gap: var(--size-space-400);
  height: 554px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 16px;
  position: relative;
  width: 404px;
}

.create .frame-18 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 55px;
}

.create .text-wrapper-7 {
  color: #cac4d0;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.15px;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create .icons-wrapper {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: 12px;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
}

.create .frame-19 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  position: relative;
  width: 100%;
}

.create .frame-20 {
  align-items: center;
  display: inline-flex;
  flex: 1 1;
  flex-direction: column;
  flex-grow: 1;
  grid-gap: 8px;
  gap: 8px;
  justify-content: flex-end;
  padding: var(--size-space-300) var(--size-space-0) 12px var(--size-space-0);
  position: relative;
}

.create .frame-1000008301 {
  color: var(--m3-schemes-outline-variant) !important;
}

.create .frame-21 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  grid-gap: var(--size-space-200);
  gap: var(--size-space-200);
  position: relative;
  width: 100%;
}

.create .button-instance {
  background-color: var(--collection-1-accent) !important;
  display: flex !important;
  height: 48px !important;
  width: 236px !important;
  color: white;
  margin-right: 10px;
}

.create .button-2 {
  margin-top: unset !important;
}

.create .button-3 {
  border: 1px solid var(--collection-1-accent) !important;
  color: var(--collection-1-accent) !important;
  background-color: black;
  height: 48px !important;
}

.create .button-4 {
  color: var(--collection-1-accent) !important;
  margin-top: unset !important;
}

.create .mini-potfolio-card-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #181818;
  border-radius: var(--shape-corner-small);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  grid-gap: var(--tokens-spacing-spacing-md);
  gap: var(--tokens-spacing-spacing-md);
  margin-left: -1px;
  margin-right: -1px;
  overflow-y: scroll;
  padding: 16px;
  position: relative;
  margin-top: 20px;
  /* width: 100%; */
  /* margin-left: 9px; */
}

.create .frame-22 {
  align-items: center;
  align-self: stretch;
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
  width: 300.5px;
}

.create .frame-23 {
  align-items: center;
  align-self: stretch;
  border-radius: var(--tokens-radius-radius-rounded);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  grid-gap: var(--tokens-spacing-spacing-xs);
  gap: var(--tokens-spacing-spacing-xs);
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;
}

.create .table-heading {
  align-items: center;
  align-self: stretch;
  background-color: #000000;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #f8f8f833;
  display: flex;
  height: 62px;
  justify-content: space-between;
  margin-left: -1px;
  margin-right: -1px;
  padding: 8px;
  position: relative;
  width: 100%;
  z-index: 6;
}

.create .row-texts {
  grid-gap: var(--size-space-200);
  gap: var(--size-space-200);
  height: 26px;
  position: relative;
  width: 26px;
}

.create .frame-wrapper-2 {
  align-items: center;
  display: flex;
  grid-gap: var(--size-space-200);
  gap: var(--size-space-200);
  position: relative;
  width: 80px;
}

.create .div-7 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: var(--size-space-100);
  gap: var(--size-space-100);
  position: relative;
}

.create .cost-efficiency {
  color: var(--collection-1-white);
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create .frame-wrapper-3 {
  align-items: center;
  display: flex;
  grid-gap: var(--size-space-200);
  gap: var(--size-space-200);
  position: relative;
  width: 92px;
}

.create .table-column-title {
  align-items: center;
  display: flex;
  grid-gap: var(--size-space-200);
  gap: var(--size-space-200);
  position: relative;
  width: 120px;
}

.create .cost-efficiency-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: var(--size-space-100);
  gap: var(--size-space-100);
  justify-content: center;
  position: relative;
}

.create .home {
  background-image: url(/icon-200.svg) !important;
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.create .table-column-title-2 {
  align-items: center;
  display: flex;
  grid-gap: var(--size-space-200);
  gap: var(--size-space-200);
  position: relative;
  width: 124px;
}

.create .row-texts-2 {
  grid-gap: var(--size-space-200);
  gap: var(--size-space-200);
  height: 16px;
  position: relative;
  width: 16px;
}

.create .balance-sheet {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: var(--size-space-200) 0px var(--size-space-200) 0px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.create .tr {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: var(--tokens-padding-padding-basic);
  position: relative;
  width: 100%;
}

.create .row-texts-3 {
  align-items: center;
  display: flex;
  grid-gap: var(--size-space-200);
  gap: var(--size-space-200);
  padding: 0px 12px 0px 0px;
  position: relative;
  width: 80px;
}

.create .cost-efficiency-2 {
  color: var(--collection-1-white);
  font-family: var(--m3-body-medium-font-family);
  font-size: var(--m3-body-medium-font-size);
  font-style: var(--m3-body-medium-font-style);
  font-weight: var(--m3-body-medium-font-weight);
  letter-spacing: var(--m3-body-medium-letter-spacing);
  line-height: var(--m3-body-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create .cost-efficiency-3 {
  color: var(--collection-1-white);
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create .menu-list-item {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 120px;
}

.create .content-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  grid-gap: 12px;
  gap: 12px;
  padding: 0px 12px;
  position: relative;
  width: 100%;
}

.create .content-4 {
  align-items: flex-start;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.create .label-text-7 {
  align-self: stretch;
  color: var(--collection-1-white);
  font-family: var(--m3-body-medium-font-family);
  font-size: var(--m3-body-medium-font-size);
  font-style: var(--m3-body-medium-font-style);
  font-weight: var(--m3-body-medium-font-weight);
  letter-spacing: var(--m3-body-medium-letter-spacing);
  line-height: var(--m3-body-medium-line-height);
  margin-top: -1px;
  position: relative;
}

.create .supporting-text-2 {
  color: var(--m3-schemes-outline-variant);
  font-family: var(--m3-body-small-font-family);
  font-size: var(--m3-body-small-font-size);
  font-style: var(--m3-body-small-font-style);
  font-weight: var(--m3-body-small-font-weight);
  letter-spacing: var(--m3-body-small-letter-spacing);
  line-height: var(--m3-body-small-line-height);
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create .finalised-instance {
  display: flex !important;
  height: unset !important;
  width: 60px !important;
}

.create .finalised-2 {
  height: unset !important;
  padding: 4px 8px !important;
}

.create .finalised-3 {
  font-family: var(--m3-label-medium-font-family) !important;
  font-size: var(--m3-label-medium-font-size) !important;
  font-style: var(--m3-label-medium-font-style) !important;
  font-weight: var(--m3-label-medium-font-weight) !important;
  letter-spacing: var(--m3-label-medium-letter-spacing) !important;
  line-height: var(--m3-label-medium-line-height) !important;
}

.create .options-instance {
  background-image: url(/system-uicons-menu-vertical-11.svg) !important;
  position: relative !important;
}

.create .tr-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: #f8f8f80d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: var(--size-space-200) 0px var(--size-space-200) 0px;
  position: relative;
  width: 100%;
  z-index: -1;
}

.create .balance-sheet-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: var(--size-space-200) 0px var(--size-space-200) 0px;
  position: relative;
  width: 100%;
  z-index: -2;
}

.create .supporting-text-3 {
  color: var(--m3-schemes-outline-variant);
  font-family: var(--m3-body-small-font-family);
  font-size: var(--m3-body-small-font-size);
  font-style: var(--m3-body-small-font-style);
  font-weight: var(--m3-body-small-font-weight);
  letter-spacing: var(--m3-body-small-letter-spacing);
  line-height: var(--m3-body-small-line-height);
  margin-right: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create .balance-sheet-3 {
  align-items: center;
  align-self: stretch;
  background-color: #f8f8f80d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: var(--size-space-200) 0px var(--size-space-200) 0px;
  position: relative;
  width: 100%;
  z-index: -3;
}

.create .options-2 {
  background-image: url(/system-uicons-menu-vertical-14.png) !important;
  position: relative !important;
}

.create .balance-sheet-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: var(--size-space-200) 0px var(--size-space-200) 0px;
  position: relative;
  width: 100%;
  z-index: -4;
}

.create .balance-sheet-5 {
  align-items: center;
  align-self: stretch;
  background-color: #f8f8f80d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: var(--size-space-200) 0px var(--size-space-200) 0px;
  position: relative;
  width: 100%;
  z-index: -5;
}

.create .createGrid {
  width: 100%;
}

.create .createGrid .rs-input {
  background-color: #181818;
  border: 0px;
  height: 50px;
  color: white;
}

.create .createGrid .rs-row {
  height: 60px;
}

.create .createGrid .labels {
  color: white;
  font-size: 18px;
  padding-top: 12px;
}

.create .createGrid .central {
  text-align: center;
}

.create .createGrid .autoMargins {
  margin: auto;
  margin-top: 10px;
}

.create .createGrid .hr {
  border-color: grey;
}

.create .createGrid .buttons {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: 8px;
  gap: 8px;
  position: relative;
}

.create .code-button {
  background-color: #3b2219;
}

.create .code-button-top {
  background-color: #3b2219;
  position: absolute;
  right: 20px;
}

.create logoLarge {
  height: 60px;
  object-fit: cover;
  position: relative;
  width: 178.26px;
  /* padding: 20px; */
  margin-top: 5px;
}

.submitButton {
  background-color: var(--collection-1-accent) !important;
  color: white !important;
  margin-top: 15px;
  position: absolute;
  top: 11px;
  right: 25px;
}

.logoutButton {
  align-items: center;
  align-self: stretch;
  background-color: rgb(40, 40, 40) !important;
  border-radius: 8px;
  color: var(--collection-1-white);
  display: flex;
  font-family: "Roboto-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 24px;
  padding: 10px 40px;
  position: relative;
  width: 255px;
  white-space: nowrap;
}

.logoutButton:hover {
  align-items: center;
  align-self: stretch;
  background-color: rgb(40, 40, 40) !important;
  border-radius: 8px;
  color: var(--collection-1-white);
  display: flex;
  font-family: "Roboto-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 24px;
  padding: 10px 40px;
  position: relative;
  width: 255px;
  white-space: nowrap;
}

.submitGrid {
  width: 100%;
}

.rs-table-row-header {
  border-radius: 5px 5px 0px 0px;
}

.rs-table-row-header .rs-table-cell {
  background-color: #000000 !important; /* Change header background color */
  color: #eeeeee; /* Change header text color */
  font-size: 14px;
}

.rs-table-body-row .rs-table-cell {
  background-color: #fff; /* Change row background color */
  color: #ffffff; /* Change row text color */
  border-bottom: 1px solid #ddd; /* Add border to rows */
}

.rs-table-row:nth-child(odd) .rs-table-cell {
  background-color: #1c1c1c;
}

.rs-table-row:nth-child(even) .rs-table-cell {
  background-color: #181818;
}

.rs-table-cell {
  color: #fff;
  border: 0px;
}

.rs-table-row:not(.rs-table-row-rowspan) {
  border: 0px;
}

.deleteButton {
  border: 1px solid red !important;
  color: red !important;
  background-color: #ffb2b2 !important;
}

.whiteText {
  color: white;
}

.file-input {
  display: none;
}

.file-label {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  line-height: 20px;
  background-color: #3c3f43;
  border: none;
  display: inline-block;
  font-weight: 400;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.file-name {
  margin-left: 10px;
  font-style: italic;
  position: relative;
  left: 205px;
  top: -35px;
}
