:root {
  --collection-1-accent: rgba(243, 118, 74, 1);
  --collection-1-accent-10: rgba(243, 118, 74, 0.1);
  --collection-1-accent-20: rgba(243, 118, 74, 0.2);
  --collection-1-accent-5: rgba(243, 118, 74, 0.05);
  --collection-1-accent-50: rgba(243, 118, 74, 0.5);
  --collection-1-BG: rgba(17, 17, 17, 1);
  --collection-1-BG-2: rgba(14, 14, 14, 1);
  --collection-1-black: rgba(12, 12, 12, 1);
  --collection-1-white: rgba(248, 248, 248, 1);
  --collection-1-yellow: rgba(254, 192, 32, 1);
  --m3-body-large-font-family: "Roboto", Helvetica;
  --m3-body-large-font-size: 16px;
  --m3-body-large-font-style: normal;
  --m3-body-large-font-weight: 400;
  --m3-body-large-letter-spacing: 0.5px;
  --m3-body-large-line-height: 24px;
  --m3-schemes-error: rgba(179, 38, 30, 1);
  --m3-schemes-on-surface: rgba(29, 27, 32, 1);
  --m3-schemes-on-surface-variant: rgba(73, 69, 79, 1);
  --m3-schemes-primary: rgba(101, 85, 143, 1);
  --m3-state-layers-error-opacity-08: rgba(179, 38, 30, 0.08);
  --m3-state-layers-error-opacity-12: rgba(179, 38, 30, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(29, 27, 32, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(29, 27, 32, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(101, 85, 143, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(101, 85, 143, 0.12);
  --neutralwhite-100: rgba(255, 255, 255, 1);
  --shape-corner-small: 5px;
  --shape-corner-large: 10px;
}

/*

To enable a theme in your HTML, simply add one of the following data attributes to an HTML element, like so:

<body data-m3-mode="light">
    <!-- the rest of your content -->
</body>

You can apply the theme on any DOM node, not just the `body`

*/

[data-m3-mode="light"] {
  --m3-schemes-error: rgba(179, 38, 30, 1);
  --m3-schemes-on-surface: rgba(29, 27, 32, 1);
  --m3-schemes-on-surface-variant: rgba(73, 69, 79, 1);
  --m3-schemes-primary: rgba(101, 85, 143, 1);
  --m3-state-layers-error-opacity-08: rgba(179, 38, 30, 0.08);
  --m3-state-layers-error-opacity-12: rgba(179, 38, 30, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(29, 27, 32, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(29, 27, 32, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(101, 85, 143, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(101, 85, 143, 0.12);
}

[data-m3-mode="light-high-contrast"] {
  --m3-schemes-error: rgba(68, 15, 14, 1);
  --m3-schemes-on-surface: rgba(0, 0, 0, 1);
  --m3-schemes-on-surface-variant: rgba(37, 35, 43, 1);
  --m3-schemes-primary: rgba(39, 23, 78, 1);
  --m3-state-layers-error-opacity-08: rgba(68, 15, 14, 0.08);
  --m3-state-layers-error-opacity-12: rgba(68, 15, 14, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(0, 0, 0, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(0, 0, 0, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(39, 23, 78, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(39, 23, 78, 0.12);
}

[data-m3-mode="light-medium-contrast"] {
  --m3-schemes-error: rgba(110, 47, 44, 1);
  --m3-schemes-on-surface: rgba(29, 27, 32, 1);
  --m3-schemes-on-surface-variant: rgba(69, 65, 74, 1);
  --m3-schemes-primary: rgba(73, 57, 113, 1);
  --m3-state-layers-error-opacity-08: rgba(110, 47, 44, 0.08);
  --m3-state-layers-error-opacity-12: rgba(110, 47, 44, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(29, 27, 32, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(29, 27, 32, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(73, 57, 113, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(73, 57, 113, 0.12);
}

[data-m3-mode="dark"] {
  --m3-schemes-error: rgba(242, 184, 181, 1);
  --m3-schemes-on-surface: rgba(230, 224, 233, 1);
  --m3-schemes-on-surface-variant: rgba(202, 196, 208, 1);
  --m3-schemes-primary: rgba(208, 188, 254, 1);
  --m3-state-layers-error-opacity-08: rgba(242, 184, 181, 0.08);
  --m3-state-layers-error-opacity-12: rgba(242, 184, 181, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(230, 224, 233, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(230, 224, 233, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(208, 188, 254, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(208, 188, 254, 0.12);
  --color-background-positive-secondary: rgba(153, 255, 130, 0.349);
}

[data-m3-mode="dark-high-contrast"] {
  --m3-schemes-error: rgba(255, 249, 249, 1);
  --m3-schemes-on-surface: rgba(255, 255, 255, 1);
  --m3-schemes-on-surface-variant: rgba(255, 249, 255, 1);
  --m3-schemes-primary: rgba(255, 249, 255, 1);
  --m3-state-layers-error-opacity-08: rgba(255, 249, 249, 0.08);
  --m3-state-layers-error-opacity-12: rgba(255, 249, 249, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(255, 255, 255, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(255, 255, 255, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(255, 249, 255, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(255, 249, 255, 0.12);
}

[data-m3-mode="dark-medium-contrast"] {
  --m3-schemes-error: rgba(255, 185, 179, 1);
  --m3-schemes-on-surface: rgba(255, 249, 255, 1);
  --m3-schemes-on-surface-variant: rgba(206, 200, 212, 1);
  --m3-schemes-primary: rgba(211, 193, 255, 1);
  --m3-state-layers-error-opacity-08: rgba(255, 185, 179, 0.08);
  --m3-state-layers-error-opacity-12: rgba(255, 185, 179, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(255, 249, 255, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(255, 249, 255, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(211, 193, 255, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(211, 193, 255, 0.12);
}

[data-m3-mode="monochrome-light"] {
  --m3-schemes-error: rgba(179, 38, 30, 1);
  --m3-schemes-on-surface: rgba(27, 27, 27, 1);
  --m3-schemes-on-surface-variant: rgba(71, 71, 71, 1);
  --m3-schemes-primary: rgba(0, 0, 0, 1);
  --m3-state-layers-error-opacity-08: rgba(179, 38, 30, 0.08);
  --m3-state-layers-error-opacity-12: rgba(179, 38, 30, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(27, 27, 27, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(27, 27, 27, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(0, 0, 0, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(0, 0, 0, 0.12);
}

[data-m3-mode="monochrome-dark"] {
  --m3-schemes-error: rgba(242, 184, 181, 1);
  --m3-schemes-on-surface: rgba(226, 226, 226, 1);
  --m3-schemes-on-surface-variant: rgba(198, 198, 198, 1);
  --m3-schemes-primary: rgba(255, 255, 255, 1);
  --m3-state-layers-error-opacity-08: rgba(242, 184, 181, 0.08);
  --m3-state-layers-error-opacity-12: rgba(242, 184, 181, 0.12);
  --m3-state-layers-on-surface-opacity-08: rgba(226, 226, 226, 0.08);
  --m3-state-layers-on-surface-opacity-12: rgba(226, 226, 226, 0.12);
  --m3-state-layers-primary-opacity-08: rgba(255, 255, 255, 0.08);
  --m3-state-layers-primary-opacity-12: rgba(255, 255, 255, 0.12);
}
