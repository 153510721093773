.icons-interaction {
  align-items: center;
  border-radius: var(--shape-corner-small);
  display: inline-flex;
  gap: 10px;
  padding: 8px;
  position: relative;
}

.icons-interaction.property-1-0-hover {
  background-color: var(--collection-1-accent-20);
}

.icons-interaction.property-1-0-selected {
  background-color: var(--collection-1-accent-20);
}
