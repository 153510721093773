.property-segmented-wrapper {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.property-segmented-wrapper .class {
  color: var(--collection-1-white) !important;
}

.property-segmented-wrapper .class-2 {
  border-color: var(--collection-1-accent-20) !important;
}

.property-segmented-wrapper .class-3 {
  background-color: var(--collection-1-accent-20) !important;
  border-color: var(--collection-1-accent-10) !important;
}

.property-segmented-wrapper .class-4 {
  background-color: var(--collection-1-accent-20) !important;
  border-color: var(--collection-1-accent-20) !important;
}

.property-segmented-wrapper .class-5 {
  color: var(--collection-1-accent) !important;
}

.property-segmented-wrapper.hover {
  flex-direction: column;
}
