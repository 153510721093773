.finalised {
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  height: 32px;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.finalised .label-text-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  height: 32px;
  justify-content: center;
  padding: 6px 16px;
  position: relative;
}

.finalised .label-text-3 {
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.finalised.finalised-false {
  background-color: rgb(249, 164, 164);
  color: red;
}

.finalised.finalised-true {
  background-color: lightgreen;
  color: green;
}

.finalised.finalised-false .label-text-3 {
  /* color: var(--m3-schemes-on-error) !important; */
}

.finalised.finalised-true .label-text-3 {
  /* color: var(--color-text-positive-on-positive-secondary) !important; */
}
