.options {
  background-image: url(/system-uicons-menu-vertical-3.svg);
  background-size: 100% 100%;
  border-radius: var(--shape-corner-extra-small);
  height: 24px;
  width: 24px;
}

.options:hover {
  background-image: url(/system-uicons-menu-vertical-4.svg);
}
