.property-default {
  align-items: center;
  background-color: #181818;
  border: 1px solid;
  border-color: #ffffff33;
  border-radius: var(--shape-corner-small);
  display: flex;
  gap: 4px;
  height: 40px;
  max-width: 720px;
  overflow: hidden;
  padding: 12px 0px 12px 12px;
  position: relative;
  width: 553px;
}

.property-default .content {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  position: relative;
}

.property-default .supporting-text {
  color: var(--collection-1-white);
  font-family: var(--m3-body-large-font-family);
  font-size: var(--m3-body-large-font-size);
  font-style: var(--m3-body-large-font-style);
  font-weight: var(--m3-body-large-font-weight);
  letter-spacing: var(--m3-body-large-letter-spacing);
  line-height: var(--m3-body-large-line-height);
  margin-bottom: -3px;
  margin-top: -5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.property-default .icons {
  align-items: center;
  background-color: var(--collection-1-white);
  border-radius: 0px var(--shape-corner-small) var(--shape-corner-small) 0px;
  display: flex;
  gap: 12px;
  height: 48px;
  justify-content: center;
  margin-bottom: -16px;
  margin-top: -16px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  width: 48px;
}

.property-default .icon-57 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}
