.x-axis {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.x-axis .element {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .feb {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .mar {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .element-2 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .element-3 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .element-4 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .element-5 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .aug {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .sep {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .element-6 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .element-7 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .element-8 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .jan {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .mar-2 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .may {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .jul {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .sep-2 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .nov {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .dec {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .text-wrapper-4 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .text-wrapper-5 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
}

.x-axis .text-wrapper-6 {
  color: var(--gray-500);
  font-family: var(--text-xs-regular-font-family);
  font-size: var(--text-xs-regular-font-size);
  font-style: var(--text-xs-regular-font-style);
  font-weight: var(--text-xs-regular-font-weight);
  letter-spacing: var(--text-xs-regular-letter-spacing);
  line-height: var(--text-xs-regular-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.x-axis.mobile {
  padding: 0px 8px;
  width: 342px;
}

.x-axis.desktop {
  padding: 0px 24px;
  width: 1216px;
}

.x-axis .breakpoint-desktop {
  text-align: center;
}

.x-axis .twelve-months {
  white-space: nowrap;
  width: fit-content;
}

.x-axis .breakpoint-mobile {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis .thirty-days.breakpoint-desktop {
  width: 16px;
}

.x-axis .breakpoint-desktop.seven-days {
  width: 16px;
}

.x-axis .breakpoint-0-desktop {
  text-align: center;
}

.x-axis .data-twelve-months {
  white-space: nowrap;
  width: fit-content;
}

.x-axis .breakpoint-0-mobile {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis .data-seven-days {
  width: fit-content;
}

.x-axis .data-thirty-days.breakpoint-0-desktop {
  width: 16px;
}

.x-axis .breakpoint-1-desktop {
  text-align: center;
}

.x-axis .data-0-twelve-months {
  white-space: nowrap;
  width: fit-content;
}

.x-axis .breakpoint-1-mobile {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis .data-0-seven-days {
  width: fit-content;
}

.x-axis .data-0-thirty-days.breakpoint-1-desktop {
  width: 16px;
}

.x-axis .breakpoint-2-desktop {
  text-align: center;
}

.x-axis .data-1-twelve-months {
  white-space: nowrap;
  width: fit-content;
}

.x-axis .breakpoint-2-mobile {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis .data-1-thirty-days.breakpoint-2-desktop {
  width: 16px;
}

.x-axis .breakpoint-2-desktop.data-1-seven-days {
  width: 16px;
}

.x-axis .breakpoint-3-desktop {
  text-align: center;
}

.x-axis .data-2-twelve-months {
  white-space: nowrap;
  width: fit-content;
}

.x-axis .breakpoint-3-mobile {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis .data-2-thirty-days.breakpoint-3-desktop {
  width: 16px;
}

.x-axis .breakpoint-3-desktop.data-2-seven-days {
  width: 16px;
}

.x-axis .breakpoint-4-desktop {
  text-align: center;
}

.x-axis .data-3-twelve-months {
  white-space: nowrap;
  width: fit-content;
}

.x-axis .breakpoint-4-mobile {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis .data-3-thirty-days.breakpoint-4-desktop {
  width: 16px;
}

.x-axis .breakpoint-4-desktop.data-3-seven-days {
  width: 16px;
}

.x-axis .breakpoint-5-desktop {
  text-align: center;
}

.x-axis .data-4-twelve-months {
  white-space: nowrap;
  width: fit-content;
}

.x-axis .breakpoint-5-mobile {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis .data-4-thirty-days.breakpoint-5-desktop {
  width: 16px;
}

.x-axis .breakpoint-5-desktop.data-4-seven-days {
  width: 16px;
}

.x-axis .breakpoint-6-desktop {
  text-align: center;
}

.x-axis .data-5-twelve-months {
  white-space: nowrap;
  width: fit-content;
}

.x-axis .breakpoint-6-mobile {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis .data-5-seven-days {
  width: fit-content;
}

.x-axis .data-5-thirty-days.breakpoint-6-desktop {
  width: 16px;
}

.x-axis .breakpoint-7-desktop {
  text-align: center;
}

.x-axis .data-6-twelve-months {
  white-space: nowrap;
  width: fit-content;
}

.x-axis .breakpoint-7-mobile {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis .data-6-seven-days {
  width: fit-content;
}

.x-axis .data-6-thirty-days.breakpoint-7-desktop {
  width: 16px;
}

.x-axis .breakpoint-8-desktop {
  text-align: center;
}

.x-axis .data-7-twelve-months {
  white-space: nowrap;
  width: fit-content;
}

.x-axis .breakpoint-8-mobile {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis .data-7-thirty-days.breakpoint-8-desktop {
  width: 16px;
}

.x-axis .breakpoint-8-desktop.data-7-seven-days {
  width: 16px;
}

.x-axis .breakpoint-9-desktop {
  text-align: center;
}

.x-axis .data-8-twelve-months {
  white-space: nowrap;
  width: fit-content;
}

.x-axis .breakpoint-9-mobile {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis .data-8-thirty-days.breakpoint-9-desktop {
  width: 16px;
}

.x-axis .breakpoint-9-desktop.data-8-seven-days {
  width: 16px;
}

.x-axis .breakpoint-10-desktop {
  text-align: center;
}

.x-axis .data-9-twelve-months {
  white-space: nowrap;
  width: fit-content;
}

.x-axis .breakpoint-10-mobile {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis .data-9-thirty-days.breakpoint-10-desktop {
  width: 16px;
}

.x-axis .breakpoint-10-desktop.data-9-seven-days {
  width: 16px;
}

.x-axis.mobile .jan {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis.desktop .jan {
  text-align: center;
  width: 56px;
}

.x-axis.mobile .mar-2 {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis.desktop .mar-2 {
  text-align: center;
  width: 56px;
}

.x-axis.mobile .may {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis.desktop .may {
  text-align: center;
  width: 56px;
}

.x-axis.mobile .jul {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis.desktop .jul {
  text-align: center;
  width: 56px;
}

.x-axis.mobile .sep-2 {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis.desktop .sep-2 {
  text-align: center;
  width: 56px;
}

.x-axis.mobile .nov {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis.desktop .nov {
  text-align: center;
  width: 56px;
}

.x-axis.mobile .dec {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis.desktop .dec {
  text-align: center;
  width: 56px;
}

.x-axis.mobile .text-wrapper-4 {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis.desktop .text-wrapper-4 {
  text-align: center;
  width: 16px;
}

.x-axis.mobile .text-wrapper-5 {
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.x-axis.desktop .text-wrapper-5 {
  text-align: center;
  width: 16px;
}

.x-axis.mobile .text-wrapper-6 {
  text-align: right;
}

.x-axis.desktop .text-wrapper-6 {
  text-align: center;
}
