.property-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.property-wrapper .class-2 {
  background-color: var(--collection-1-accent-50) !important;
}

.property-wrapper .checkboxes-instance {
  padding: 4px !important;
}

.property-wrapper .checkboxes-2 {
  flex: 0 0 auto !important;
  padding: unset !important;
}

.property-wrapper .class-3 {
  height: 24px !important;
  left: 1px !important;
  position: absolute !important;
  top: 1px !important;
  width: 24px !important;
}

.property-wrapper .class-4 {
  border-color: var(--collection-1-accent-50) !important;
}
