.building-blocks-segmented-button-button-segment-end {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 4px 0px;
  position: relative;
  width: 108px;
}

.building-blocks-segmented-button-button-segment-end .container-2 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-radius: 0px 100px 100px 0px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.building-blocks-segmented-button-button-segment-end .state-layer-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  justify-content: center;
  padding: 10px 12px;
  position: relative;
}

.building-blocks-segmented-button-button-segment-end .icon-98 {
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}

.building-blocks-segmented-button-button-segment-end .label-text-4 {
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.building-blocks-segmented-button-button-segment-end.state-4-hovered {
  flex-direction: column;
}

.building-blocks-segmented-button-button-segment-end.state-4-pressed {
  flex-direction: column;
}

.building-blocks-segmented-button-button-segment-end.state-4-focused {
  flex-direction: column;
}

.building-blocks-segmented-button-button-segment-end .state-5-hovered {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .selected-4-true {
  background-color: var(--m3-schemes-secondary-container);
}

.building-blocks-segmented-button-button-segment-end .state-5-pressed {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .state-5-disabled {
  border-color: #1d1b201f;
}

.building-blocks-segmented-button-button-segment-end .configuration-2-label-only {
  flex-direction: column;
}

.building-blocks-segmented-button-button-segment-end .state-5-enabled {
  border-color: var(--m3-schemes-outline);
  gap: 10px;
}

.building-blocks-segmented-button-button-segment-end .state-5-focused {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .configuration-2-label-icon {
  flex-direction: column;
}

.building-blocks-segmented-button-button-segment-end .state-5-enabled.selected-4-true {
  flex-direction: column;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-hovered .state-layer-3 {
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-pressed .state-layer-3 {
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .container-2.configuration-2-label-only .state-layer-3 {
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-focused .state-layer-3 {
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .container-2.configuration-2-label-icon .state-layer-3 {
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .container-2.selected-4-true.state-5-hovered .state-layer-3 {
  background-color: #1d192b14;
}

.building-blocks-segmented-button-button-segment-end .container-2.selected-4-false.state-5-hovered .state-layer-3 {
  background-color: #1d1b2014;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-pressed.selected-4-true .state-layer-3 {
  background-color: #1d192b1f;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-focused.selected-4-true .state-layer-3 {
  background-color: #1d192b1f;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-focused.selected-4-false .state-layer-3 {
  background-color: #1d1b201f;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-enabled.selected-4-true .state-layer-3 {
  width: 100%;
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-pressed.selected-4-false .state-layer-3 {
  background-color: #1d1b201f;
}

.building-blocks-segmented-button-button-segment-end .container-2.selected-4-false .label-text-4 {
  color: var(--m3-schemes-on-surface);
}

.building-blocks-segmented-button-button-segment-end .container-2.state-5-disabled .label-text-4 {
  opacity: 0.38;
}

.building-blocks-segmented-button-button-segment-end .container-2.selected-4-true .label-text-4 {
  color: var(--m3-schemes-on-secondary-container);
}
