.login {
    background-color: #0e0f11;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }
  
  .login .overlap-wrapper {
    background-color: #0e0f11;
    height: 1040px;
    overflow: hidden;
    width: 1512px;
  }
  
  .login .overlap {
    height: 1172px;
    left: -189px;
    position: relative;
    top: -132px;
    width: 1890px;
  }
  
  .login .BG {
    height: 1157px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 1890px;
  }
  
  .login .overlap-group {
    height: 1157px;
    position: relative;
  }
  
  .login .pattern {
    height: 1019px;
    left: 0;
    position: absolute;
    top: 45px;
    width: 1890px;
  }
  
  .login .div {
    height: 1019px;
    left: 179px;
    position: relative;
    width: 1522px;
  }
  
  .login .pattern-2 {
    height: 1019px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1510px;
  }
  
  .login .line {
    height: 948px;
    left: 760px;
    object-fit: cover;
    position: absolute;
    top: 72px;
    width: 1px;
  }
  
  .login .overlap-2 {
    height: 921px;
    left: 838px;
    position: absolute;
    top: 72px;
    width: 673px;
  }
  
  .login .img {
    height: 921px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 455px;
  }
  
  .login .line-2 {
    height: 327px;
    left: 172px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 501px;
  }
  
  .login .line-3 {
    height: 541px;
    left: 84px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 552px;
  }
  
  .login .overlap-group-2 {
    height: 861px;
    left: 10px;
    position: absolute;
    top: 72px;
    width: 671px;
  }
  
  .login .line-4 {
    height: 861px;
    left: 216px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 455px;
  }
  
  .login .line-5 {
    height: 317px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 490px;
  }
  
  .login .line-6 {
    height: 540px;
    left: 6px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 552px;
  }
  
  .login .pattern-3 {
    height: 489px;
    left: 10px;
    position: absolute;
    top: 72px;
    width: 1512px;
  }
  
  .login .rectangle {
    background: linear-gradient(180deg, rgba(14, 15, 17, 0) 0%, rgb(14, 15, 17) 35.86%);
    height: 1157px;
    left: 45px;
    position: absolute;
    top: 0;
    width: 1800px;
  }
  
  .login .gradient {
    background-color: #f3764ab8;
    border-radius: 500px/100px;
    filter: blur(600px);
    height: 200px;
    left: 445px;
    position: absolute;
    top: 0;
    width: 1000px;
  }
  
  .login .content {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 40px;
    left: 640px;
    position: absolute;
    top: 354px;
  }
  
  .login .frame {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    position: relative;
    /* width: 478px; */
  }
  
  .login .title {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 24px;
    position: relative;
  }
  
  .login .text-wrapper {
    color: var(--collection-1-white);
    font-family: "Roboto-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .login .title-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    position: relative;
    width: 100%;
  }
  
  .login .white-logo {
    height: 60.26px;
    object-fit: cover;
    position: relative;
    width: 64px;
  }
  
  .login .p {
    color: var(--collection-1-white);
    font-family: "Roboto-Regular", Helvetica;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .login .content-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 40px;
    position: relative;
  }
  
  .login .form {
    align-items: flex-start;
    background-color: #ffffff29;
    border: 1px solid;
    border-color: #ffffff0f;
    border-radius: 16px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    overflow: hidden;
    padding: 48px 48px 40px;
    position: relative;
    width: 584px;
  }
  
  .login .dot {
    height: 200px;
    left: 0;
    position: absolute;
    top: 196px;
    width: 584px;
  }
  
  .login .frame-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
  }
  
  .login .text-field {
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    flex-direction: column;
    height: 56px;
    position: relative;
    width: 100%;
  }
  
  .login .state-layer-wrapper {
    align-items: flex-start;
    align-self: stretch;
    background-color: #e6e0e914;
    border: 1px solid;
    border-color: #ffffff1f;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    position: relative;
    width: 100%;
  }
  
  .login .state-layer-2 {
    align-items: center;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    padding: 4px 0px;
    position: relative;
    width: 100%;
  }
  
  .login .frame-3 {
    align-items: center;
    display: flex;
    gap: 8px;
    height: 48px;
    justify-content: center;
    position: relative;
    width: 48px;
  }
  
  .login .icon-instance-node {
    height: 20px !important;
    position: relative !important;
    width: 20px !important;
  }
  
  .login .label-text-wrapper {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    height: 48px;
    justify-content: center;
    padding: 4px 0px;
    position: relative;
  }
  
  .login .label-text {
    align-items: center;
    background: none;
    border: 0;
    color: var(--collection-1-white);
    display: inline-flex;
    flex: 0 0 auto;
    font-family: var(--m3-body-large-font-family);
    font-size: var(--m3-body-large-font-size);
    font-style: var(--m3-body-large-font-style);
    font-weight: var(--m3-body-large-font-weight);
    height: 23px;
    letter-spacing: var(--m3-body-large-letter-spacing);
    line-height: var(--m3-body-large-line-height);
    margin-top: -1px;
    padding: 0;
    position: relative;
    white-space: nowrap;
    width: 385px;
  }
  
  .login .active-indicator {
    align-self: stretch;
    height: 1px;
    object-fit: cover;
    position: relative;
    width: 100%;
  }
  
  .login .frame-4 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
  }
  
  .login .frame-5 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }
  
  .login .text-wrapper-2 {
    color: var(--collection-1-accent);
    font-family: "Roboto-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .login .input {
    align-self: stretch;
    background: none;
    border: 0;
    color: var(--collection-1-white);
    font-family: var(--m3-body-large-font-family);
    font-size: var(--m3-body-large-font-size);
    font-style: var(--m3-body-large-font-style);
    font-weight: var(--m3-body-large-font-weight);
    height: 23px;
    letter-spacing: var(--m3-body-large-letter-spacing);
    line-height: var(--m3-body-large-line-height);
    margin-top: -1px;
    padding: 0;
    position: relative;
    white-space: nowrap;
    width: 100%;
  }
  
  .login .frame-6 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .login .checkboxes-instance {
    flex: 0 0 auto !important;
  }
  
  .login .design-component-instance-node {
    background-color: var(--collection-1-accent) !important;
  }
  
  .login .text-wrapper-3 {
    color: var(--neutralwhite-100);
    font-family: "Roboto-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
    margin-left: -8px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .loginButton {
    align-items: center;
    align-self: stretch;
    background-color: var(--collection-1-accent) !important;
    border-radius: 8px;
    color: var(--collection-1-black);
    display: flex;
    font-family: "Roboto-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    height: 48px;
    justify-content: center;
    letter-spacing: 0;
    line-height: 24px;
    padding: 10px 40px;
    position: relative;
    white-space: nowrap;
    width: 300px;
    /* width: fit-content; */
  }
  
  .login .foot {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 584px;
  }
  
  .login .frame-7 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    position: relative;
  }
  
  .login .ellipse {
    background-color: #454a53;
    border-radius: 1.5px;
    height: 3px;
    position: relative;
    width: 3px;
  }
  
  .login .frame-8 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
  }
  
  .login .bee {
    height: 123px;
    left: 883px;
    object-fit: cover;
    position: absolute;
    top: 188px;
    width: 123px;
  }

  .inputGroup {
    align-self: stretch;
    border: 1px solid !important;
    border-color: #ffffff1f;
    display: flex;
    flex: 1;
    flex-grow: 1;
    position: relative;
    width: 100%;
  }
  
  .inputGroup .inputField {
    background-color: #e6e0e914 !important;
    height: 64px;
    color: white;
  }

  .cursorPointer {
    cursor: pointer !important;
  }

  .loginButton {
    align-items: center;
    align-self: stretch;
    background-color: var(--collection-1-accent);
    border-radius: 8px;
    color: var(--collection-1-black);
    display: flex;
    font-family: "Roboto-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    height: 48px;
    justify-content: center;
    letter-spacing: 0;
    line-height: 24px;
    padding: 10px 40px;
    position: relative;
    width: 100%;
    white-space: nowrap;
  }
  