.icon.instance-node {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.icon.graph-icon-wrapper {
  height: 24px;
  position: relative;
  width: 24px;
}

.icon .overlap-group-wrapper {
  position: relative;
}

.icon .overlap-group {
  position: relative;
}

.icon .vector-shape {
  height: 17px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 17px;
}

.icon .img {
  height: 9px;
  left: 11px;
  position: absolute;
  top: 0;
  width: 9px;
}

.icon .div {
  border-radius: 4px;
  height: 22px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 22px;
}

.icon .text {
  color: var(--token-design-1-text-colors-text-colors-white);
  font-family: var(--semibold-16px-font-family);
  font-size: var(--semibold-16px-font-size);
  font-style: var(--semibold-16px-font-style);
  font-weight: var(--semibold-16px-font-weight);
  height: 24px;
  left: 6px;
  letter-spacing: var(--semibold-16px-letter-spacing);
  line-height: var(--semibold-16px-line-height);
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.icon .sort {
  height: 24px;
  left: 1px;
}

.icon .check {
  height: 24px;
  left: 1px;
}

.icon .noti-01 {
  height: 24px;
  left: 1px;
}

.icon .posts-icon {
  height: 24px;
  left: 1px;
}

.icon .user {
  height: 24px;
  left: 1px;
}

.icon .filter-1 {
  height: 24px;
  left: 1px;
}

.icon .exit {
  height: 24px;
  left: 1px;
}

.icon .moon-icon {
  height: 24px;
  left: 1px;
}

.icon .sort-2 {
  height: 24px;
  left: 1px;
}

.icon .sort-3 {
  height: 24px;
  left: 1px;
}

.icon .send-icon {
  height: 24px;
  left: 1px;
}

.icon .add-icon {
  height: 24px;
  left: 1px;
}

.icon .transaction-list {
  height: 24px;
  left: 1px;
}

.icon .up-arrow-icon {
  height: 24px;
  left: 1px;
}

.icon .filter {
  height: 24px;
  left: 1px;
}

.icon .sort-1 {
  height: 24px;
  left: 1px;
}

.icon .noti-02 {
  height: 24px;
  left: 1px;
}

.icon .home-icon {
  height: 24px;
  left: 1px;
}

.icon .list {
  height: 24px;
  left: 1px;
}

.icon .settings {
  height: 24px;
  left: 1px;
}

.icon .clarity-code-line {
  height: 24px;
  left: 1px;
}

.icon .chat-icon {
  height: 24px;
  left: 1px;
}

.icon .audience-icon {
  height: 24px;
  left: 1px;
}

.icon .sun-icon {
  height: 24px;
  left: 1px;
}

.icon .schedule-icon {
  height: 24px;
  left: 1px;
}

.icon .sign {
  height: 24px;
  left: 1px;
}

.icon .exchange {
  height: 24px;
  left: 1px;
}

.icon .add-circle-icon {
  height: 24px;
  left: 1px;
}

.icon .heart-icon {
  height: 24px;
  left: 1px;
}

.icon .alert {
  height: 24px;
  left: 1px;
}

.icon .mingcute-search-line {
  height: 24px;
  left: 1px;
}

.icon .down-arrow-icon {
  height: 24px;
  left: 1px;
}

.icon .notification-icon {
  height: 24px;
  left: 1px;
}

.icon .graph-icon {
  height: 18px;
  left: 3px;
  top: 3px;
  width: 19px;
}

.icon .discount-icon {
  height: 24px;
  left: 1px;
}

.icon .overlap-group-wrapper.sort .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.check .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.noti-01 .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.posts-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.user .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.filter-1 .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.exit .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.moon-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.sort-2 .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.sort-3 .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.send-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.add-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.transaction-list .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.up-arrow-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.filter .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.sort-1 .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.noti-02 .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.home-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.list .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.settings .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.clarity-code-line .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.chat-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.audience-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.sun-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.schedule-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.sign .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.exchange .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.add-circle-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.heart-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.alert .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.mingcute-search-line .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.down-arrow-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.notification-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.graph-icon .overlap-group {
  height: 20px;
  left: -1px;
  top: -1px;
  width: 20px;
}

.icon .overlap-group-wrapper.discount-icon .overlap-group {
  height: 24px;
  width: 22px;
}

.icon .overlap-group-wrapper.sort .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.check .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.noti-01 .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.posts-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.user .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.filter-1 .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.exit .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.moon-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.sort-2 .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.sort-3 .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.send-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.add-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.transaction-list .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.up-arrow-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.filter .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.sort-1 .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.noti-02 .div {
  background-color: var(--primary-colors-blue-color);
}

.icon .overlap-group-wrapper.home-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.list .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.settings .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.clarity-code-line .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.chat-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.audience-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.sun-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.schedule-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.sign .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.exchange .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.add-circle-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.heart-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.alert .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.mingcute-search-line .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.down-arrow-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.notification-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.graph-icon .div {
  background-color: var(--collection-1-accent);
}

.icon .overlap-group-wrapper.discount-icon .div {
  background-color: var(--collection-1-accent);
}
