.currency {
  align-items: center;
  border-radius: var(--shape-corner-extra-small);
  gap: 4px;
  position: relative;
}

.currency .money-pound {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.currency .frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 4px;
  position: relative;
}

.currency .text-wrapper-3 {
  color: var(--collection-1-black);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.currency .money-pound-wrapper {
  align-items: center;
  background-color: var(--collection-1-accent-20);
  border-radius: var(--shape-corner-extra-small);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 8px;
  position: relative;
}

.currency.property-1-variant-4 {
  display: inline-flex;
}

.currency.property-1-variant-5 {
  background-color: var(--collection-1-accent-10);
  border: 1px solid;
  border-color: #f8f8f833;
  display: inline-flex;
  padding: 0px 8px 0px 0px;
}

.currency.property-1-icon {
  border: 1px solid;
  border-color: #f8f8f833;
  display: inline-flex;
  padding: 0px 8px 0px 0px;
}

.currency.property-1-hover {
  background-color: var(--collection-1-accent-10);
  display: flex;
  width: 118px;
}

.currency.property-1-default {
  display: flex;
  width: 118px;
}
