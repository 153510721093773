.configuration-icon-wrapper {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 4px 0px;
  position: relative;
  width: 108px;
}

.configuration-icon-wrapper .state-layer-wrapper {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.configuration-icon-wrapper .state-layer-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  justify-content: center;
  padding: 10px 12px;
  position: relative;
}

.configuration-icon-wrapper .icon-88 {
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}

.configuration-icon-wrapper .label-text-3 {
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.configuration-icon-wrapper.state-1-hovered {
  flex-direction: column;
}

.configuration-icon-wrapper.state-1-pressed {
  flex-direction: column;
}

.configuration-icon-wrapper.state-1-focused {
  flex-direction: column;
}

.configuration-icon-wrapper .state-2-hovered {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  width: 100%;
}

.configuration-icon-wrapper .selected-1-true {
  background-color: var(--m3-schemes-secondary-container);
}

.configuration-icon-wrapper .state-2-pressed {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  width: 100%;
}

.configuration-icon-wrapper .state-2-disabled {
  border-color: #1d1b201f;
}

.configuration-icon-wrapper .configuration-0-label-only {
  flex-direction: column;
}

.configuration-icon-wrapper .state-2-enabled {
  border-color: var(--m3-schemes-outline);
}

.configuration-icon-wrapper .state-2-focused {
  border-color: var(--m3-schemes-outline);
  flex-direction: column;
  width: 100%;
}

.configuration-icon-wrapper .configuration-0-label-icon {
  flex-direction: column;
}

.configuration-icon-wrapper .state-2-enabled.selected-1-true {
  flex-direction: column;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-hovered .state-layer-2 {
  width: 100%;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-pressed .state-layer-2 {
  width: 100%;
}

.configuration-icon-wrapper .state-layer-wrapper.configuration-0-label-only .state-layer-2 {
  width: 100%;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-focused .state-layer-2 {
  width: 100%;
}

.configuration-icon-wrapper .state-layer-wrapper.configuration-0-label-icon .state-layer-2 {
  width: 100%;
}

.configuration-icon-wrapper .state-layer-wrapper.selected-1-true.state-2-hovered .state-layer-2 {
  background-color: #1d192b14;
}

.configuration-icon-wrapper .state-layer-wrapper.selected-1-false.state-2-hovered .state-layer-2 {
  background-color: #1d1b2014;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-pressed.selected-1-true .state-layer-2 {
  background-color: #1d192b1f;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-focused.selected-1-true .state-layer-2 {
  background-color: #1d192b1f;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-focused.selected-1-false .state-layer-2 {
  background-color: #1d1b201f;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-enabled.selected-1-true .state-layer-2 {
  width: 100%;
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-pressed.selected-1-false .state-layer-2 {
  background-color: #1d1b201f;
}

.configuration-icon-wrapper .state-layer-wrapper.selected-1-false .label-text-3 {
  color: var(--m3-schemes-on-surface);
}

.configuration-icon-wrapper .state-layer-wrapper.state-2-disabled .label-text-3 {
  opacity: 0.38;
}

.configuration-icon-wrapper .state-layer-wrapper.selected-1-true .label-text-3 {
  color: var(--m3-schemes-on-secondary-container);
}
