.frame-wrapper {
  align-items: center;
  display: flex;
  gap: 8px;
  padding: var(--size-space-200) var(--size-space-100) var(--size-space-200) var(--size-space-100);
  position: relative;
  width: 370px;
}

.frame-wrapper .amount-sent {
  color: var(--m3-schemes-outline);
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 20px;
  position: relative;
}

.frame-wrapper .element-USD {
  color: #ffffff;
  flex: 1;
  font-family: var(--m3-title-medium-font-family);
  font-size: var(--m3-title-medium-font-size);
  font-style: var(--m3-title-medium-font-style);
  font-weight: var(--m3-title-medium-font-weight);
  letter-spacing: var(--m3-title-medium-letter-spacing);
  line-height: var(--m3-title-medium-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
}
