.div-wrapper {
  align-items: center;
  background-color: var(--collection-1-accent-20);
  border-radius: var(--shape-corner-extra-small);
  display: flex;
  gap: 10px;
  height: 20px;
  justify-content: center;
  padding: 4px;
  position: relative;
  width: 20px;
}

.div-wrapper .ph-copy-duotone {
  height: 16px !important;
  margin-bottom: -2px !important;
  margin-left: -2px !important;
  margin-right: -2px !important;
  margin-top: -2px !important;
  position: relative !important;
  width: 16px !important;
}
