.chart-data.series-chart-type {
  height: 192px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1101px;
}

.chart-data.chart-wrapper {
  height: 192px;
}

.chart-data .chart {
  align-items: flex-end;
  display: flex;
  height: 192px;
  justify-content: space-between;
  position: relative;
}

.chart-data .bar {
  align-self: stretch;
  position: relative;
}

.chart-data .chart-bar {
  height: 144px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 48px !important;
  width: 32px !important;
}

.chart-data .chart-bar-2 {
  height: 144px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 48px !important;
  width: 16px !important;
}

.chart-data .chart-bar-wrapper {
  align-self: stretch;
  position: relative;
}

.chart-data .chart-bar-3 {
  height: 176px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 16px !important;
  width: 32px !important;
}

.chart-data .chart-bar-4 {
  height: 176px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 16px !important;
  width: 16px !important;
}

.chart-data .bar-2 {
  align-self: stretch;
  position: relative;
}

.chart-data .chart-bar-5 {
  height: 112px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 80px !important;
  width: 32px !important;
}

.chart-data .chart-bar-6 {
  height: 112px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 80px !important;
  width: 16px !important;
}

.chart-data .bar-3 {
  align-self: stretch;
  position: relative;
}

.chart-data .chart-bar-7 {
  height: 152px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 40px !important;
  width: 32px !important;
}

.chart-data .chart-bar-8 {
  height: 152px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 40px !important;
  width: 16px !important;
}

.chart-data .bar-4 {
  align-self: stretch;
  position: relative;
}

.chart-data .bar-5 {
  align-self: stretch;
  position: relative;
}

.chart-data .chart-bar-9 {
  height: 168px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 24px !important;
  width: 32px !important;
}

.chart-data .chart-bar-10 {
  height: 168px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 24px !important;
  width: 16px !important;
}

.chart-data .bar-6 {
  align-self: stretch;
  position: relative;
}

.chart-data .bar-7 {
  align-self: stretch;
  position: relative;
}

.chart-data .bar-8 {
  align-self: stretch;
  position: relative;
}

.chart-data .bar-9 {
  align-self: stretch;
  position: relative;
}

.chart-data .chart-bar-11 {
  height: 160px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 32px !important;
  width: 32px !important;
}

.chart-data .chart-bar-12 {
  height: 160px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 32px !important;
  width: 16px !important;
}

.chart-data .bar-10 {
  align-self: stretch;
  position: relative;
}

.chart-data .bar-11 {
  align-self: stretch;
  position: relative;
}

.chart-data .chart-bar-13 {
  height: 136px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 56px !important;
  width: 32px !important;
}

.chart-data .chart-bar-14 {
  height: 136px !important;
  left: 0 !important;
  object-fit: cover !important;
  position: absolute !important;
  top: 56px !important;
  width: 16px !important;
}

.chart-data.bar-desktop {
  width: 1100px;
}

.chart-data.bar-mobile {
  width: 343px;
}

.chart-data.line-wavy {
  width: 1100px;
}

.chart-data.line-volatile {
  width: 1100px;
}

.chart-data.line-gradual {
  width: 1100px;
}

.chart-wrapper.bar-desktop .chart {
  padding: 0px 20px;
  width: 1100px;
}

.chart-wrapper.bar-mobile .chart {
  padding: 0px 12px;
  width: 343px;
}

.chart-wrapper.line-wavy .chart {
  padding: 0px 20px;
  width: 1100px;
}

.chart-wrapper.line-volatile .chart {
  padding: 0px 20px;
  width: 1100px;
}

.chart-wrapper.line-gradual .chart {
  padding: 0px 20px;
  width: 1100px;
}

.chart-wrapper.bar-desktop .bar {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar {
  width: 16px;
}

.chart-wrapper.line-wavy .bar {
  width: 32px;
}

.chart-wrapper.line-volatile .bar {
  width: 32px;
}

.chart-wrapper.line-gradual .bar {
  width: 32px;
}

.chart-wrapper.bar-desktop .chart-bar-wrapper {
  width: 32px;
}

.chart-wrapper.bar-mobile .chart-bar-wrapper {
  width: 16px;
}

.chart-wrapper.line-wavy .chart-bar-wrapper {
  width: 32px;
}

.chart-wrapper.line-volatile .chart-bar-wrapper {
  width: 32px;
}

.chart-wrapper.line-gradual .chart-bar-wrapper {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-2 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-2 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-2 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-2 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-2 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-3 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-3 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-3 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-3 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-3 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-4 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-4 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-4 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-4 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-4 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-5 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-5 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-5 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-5 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-5 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-6 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-6 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-6 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-6 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-6 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-7 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-7 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-7 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-7 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-7 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-8 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-8 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-8 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-8 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-8 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-9 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-9 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-9 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-9 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-9 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-10 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-10 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-10 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-10 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-10 {
  width: 32px;
}

.chart-wrapper.bar-desktop .bar-11 {
  width: 32px;
}

.chart-wrapper.bar-mobile .bar-11 {
  width: 16px;
}

.chart-wrapper.line-wavy .bar-11 {
  width: 32px;
}

.chart-wrapper.line-volatile .bar-11 {
  width: 32px;
}

.chart-wrapper.line-gradual .bar-11 {
  width: 32px;
}
